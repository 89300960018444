

import React, {FC, useEffect, useState, Fragment} from "react";
import { useParams } from "react-router-dom";
import { Patient } from "../../types/patient";
import { buildGetFetch } from "../../services/base";
import { Spinner, Alert } from "react-bootstrap/esm";

export const PatientDetail: FC<{}> = () => {

    let {id} = useParams();

    const [patient, setPatient] = useState<Patient>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const [fetch, cancel] = buildGetFetch<Patient>(`/patient/${id}`, {}, setPatient, setLoading, setError);
        fetch();
    }, []);

    if(error){
        return (<Alert variant="warning">Paziente non trovato</Alert>);
    }

    if(loading){
        return (<Spinner animation="border" role="status">
        <span className="sr-only">Caricamento patiente...</span>
      </Spinner>);
    }
    return (
        <Fragment>
            <div>Pagina paziente</div>
            {patient && 
                <div>
                    Paziente {patient.name}
                </div>
            }
        </Fragment>
    )
}