
import React, { FC, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { LesionSettings } from "./lesionSettings";
import { Support } from "./support";

export const SettingsCenter: FC<{}> = () => {

    const [tabKey, setTabKey] = useState('support');
   
        return (
            <div>
                <h3>Impostazioni</h3>
                <Tabs className="mt-4" activeKey={tabKey} id="tabs" onSelect={(k: string) => {setTabKey(k)}}>
                    <Tab eventKey="support" title="Calendario supporto">
                        <Support/>
                    </Tab>
                    { /*
                    <Tab eventKey="lesionSettings" title="Elenco lesioni">
                        <LesionSettings />
                    </Tab>
                    */}
                </Tabs>
            </div>
        )
    
}
