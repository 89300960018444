
import React, { FC, useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from '../services/auth';
import { Button, Container, Jumbotron, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LoadingButton } from './common/loadingbutton';

const Login: FC<any> = () => {
    const [logging, setLogging] = useState(false);
    const [version, setVersion] = useState("(...)");

    let emailInput = useRef<HTMLInputElement>(null);
    let passwordInput = useRef<HTMLInputElement>(null);
    let history = useHistory();

    const zapCacheAndReload = () => {
        try {
        if (caches) {
            caches.keys().then(function(names) {
              for (let name of names) caches.delete(name);
            });
          }
        } catch(e) {}
        window.location.reload(true);
    }

    useEffect(() => {
        // verifichiamo la versione applicativa
        var myHeaders = new Headers();
            myHeaders.append('pragma', 'no-cache');
            myHeaders.append('cache-control', 'no-cache');
        var myInit = {
            method: 'GET',
            headers: myHeaders,
        };
        fetch('/app-info.json',myInit)
            .then((response) => response.json())
            .then((serverInfo) => {
                console.log("server app-info",serverInfo);
                let savedAppInfoAsString=localStorage.getItem("app-info");
                let savedAppInfo=null;
                if(savedAppInfoAsString) {
                    try {
                        savedAppInfo=JSON.parse(savedAppInfoAsString);
                    } catch(e) {
                        savedAppInfo=null;
                    }
                }
                if(!savedAppInfo || !savedAppInfo.version) {
                    console.log("No saved version, storing the server app-info");
                    localStorage.setItem("app-info",JSON.stringify(serverInfo));
                    setVersion(serverInfo.version);
                } else {
                    console.log("Saved version present, comparing versions",savedAppInfo.version,serverInfo.version);
                    if(savedAppInfo.version!=serverInfo.version) {
                        console.log("Version mismatch, reloading....");
                        localStorage.removeItem("app-info");
                        zapCacheAndReload();
                    } else {
                        setVersion(savedAppInfo.version);
                    }
                }
            });
        });

    const login = (e: any) => {
        setLogging(true);
        e.preventDefault();
        const email = emailInput.current?.value;
        const password = passwordInput.current?.value;
        if (email && email !== '' && password && password !== '') {
            Auth.login(email, password).then(() => {
                setLogging(false);
                history.push('/app');
            }).catch(() => {
                setLogging(false);
                toast("Login fallito");
            });
        }
    }

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={6}>
                    <h1  className="mt-5 mb-5" style={{textAlign: 'center', color: 'var(--theme-color1)'}}>Admin panel</h1>
                    <div className="login-version">Versione: {version}</div>
                    <Jumbotron className="shadow">
                        <Form onSubmit={login}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Utente</Form.Label>
                                <Form.Control ref={emailInput} type="email" placeholder="Nome utente" />
                                <Form.Text className="text-muted">
                                    Inserisci il tuo nome utente (email)
                                </Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" ref={passwordInput} />
                            </Form.Group>
                            { /*
                            <Form.Group controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Check me out" />
                            </Form.Group> */}
                            <LoadingButton className="mt-4" block disabled={logging} loading={logging} variant="info" type="submit">Login</LoadingButton>
                        </Form>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    )
};

export { Login };