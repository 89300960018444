//bootstrap customization and import
import './bootstrap.scss';
//toast css
import 'react-toastify/dist/ReactToastify.css';
//date picker css
import "react-datepicker/dist/react-datepicker.css";
//main css
import './assets/css/index.css';
//theming stuff
import './assets/css/theme.css';

import React from 'react';
import ReactDOM from 'react-dom';
import Main from './main';
import * as serviceWorker from './serviceWorker';
import {Auth} from './services/auth';
import { ToastContainer } from 'react-toastify';
import { setupVersioning } from './utils/versioning';
import { EMOJI } from './utils/emoji';
import it from 'date-fns/locale/it';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

registerLocale('it', it);

setDefaultLocale('it');

setupVersioning();

Auth.start().then(() => {
  ReactDOM.render(
    <React.Fragment>
      <Main/>
      <ToastContainer/>
    </React.Fragment>,
    document.getElementById('root')
  );
  serviceWorker.unregister();
}).catch((reason: any) => {
  ReactDOM.render(
    <React.StrictMode>
      <div style={{textAlign: 'center', marginTop: '25vh'}}>
        <h4>{EMOJI.INVALID}</h4>
        <p>Server non raggiungibile</p>
        <p>Contatta il supporto</p>
        <pre>{JSON.stringify(reason.message)}</pre>
      </div>
    </React.StrictMode>,
    document.getElementById('root')
  );
});



