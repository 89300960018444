
import React, {FC} from 'react';
import { Badge } from 'react-bootstrap';
import { EXAM_STATE } from '../../types/enums';

export const StateBadge: FC<{state: EXAM_STATE}> = ({state}) => {

    switch (state) {
        case EXAM_STATE.IN_EXECUTION : {
            return <Badge variant="success">Aperta</Badge>
        }
        case EXAM_STATE.CLOSED : {
            return <Badge variant="dark">Chiusa</Badge>
        }
        case EXAM_STATE.ARCHIVED : {
            return <Badge variant="info">Archiviata</Badge>
        }
        default: {
            return <Badge variant="light"> - </Badge>
        }
    }

}