

import React, {FC, useCallback} from 'react';
import { Badge } from 'react-bootstrap';
import { fakeId } from '../../utils/faker';
import { useHistory } from 'react-router-dom';

export const Id: FC<{id: number, goTo?: "exam" | "patient"}> = ({id, goTo}) => {
    const hs = useHistory();
    const goToCb = useCallback(() => {
        if(goTo && goTo === "exam"){
            hs.push(`/app/exams/${id}`);
        }
        if(goTo && goTo === "patient"){
            hs.push(`/app/patients/${id}`);
        }
    }, [id, goTo]);
    if(goTo){
        return <Badge onClick={goToCb} style={{cursor: 'pointer'}} variant="dark">{fakeId(id)}</Badge>;
    }
    return <Badge style={{cursor: 'pointer'}} variant="dark">{fakeId(id)}</Badge>;
}