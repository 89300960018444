
import React, { FC, useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { buildGetFetch, buildPostFetch } from "../../services/base";
import { Exam } from "../../types/exam";
import { formatDT, formatTS } from '../../utils/dateutils';
import { Patient } from "../../types/patient";
import { User } from "../../types/user";
import { Loading } from "./loading";

type LogsProps = {
    patient?: Patient,
    exam?: Exam,
    user?: User,
    onClose: () => void
}

const Logs: FC<LogsProps> = ({patient=null,exam=null,user=null,onClose}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [data,setData] = useState<any>();

    useEffect(() => {
        setIsLoading(true);
        let pars:any={};
        if(patient!=null) pars["patient_id"]=patient.id;
        if(user!=null) pars["user_id"]=user.id;
        if(exam!=null) pars["exam_id"]=exam.id;
        const [search] = buildPostFetch<any>("/logs",pars);
        search().then((v) => {
            setData(v);
            setIsLoading(false);
        });
    },[]);

    return (
        <Modal size="xl" show={true} backdrop="static" keyboard={false}>
            <Modal.Header>
                <h5>Log modifiche</h5>
            </Modal.Header>
            <Modal.Body>
                <Loading show={isLoading} />
                {!isLoading && data &&
                    <div>
                        <Table hover size="sm">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Data e ora</th>
                                    <th>Autore</th>
                                    <th>Messaggio</th>
                                    <th>Paziente</th>
                                    <th>Visita</th>
                                    <th>Utente</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.length === 0 && <tr><td colSpan={6}>Nessun messaggio di log</td></tr>}
                                {data.map((r:any) => {
                                    let css="bg-light";
                                    if(r.severity==1) css="bg-warning";
                                    else if(r.severity==2) css="bg-danger";
                                    return (
                                        <tr key={r.id}>
                                            <td className={css}></td>
                                            <td>
                                                {formatTS(r.created_ts)}
                                            </td>
                                            <td>
                                                {r.author &&
                                                    <span className="author_user">{r.author.surname} {r.author.name}</span>
                                                }                                                
                                                {r.author_admin &&
                                                    <span className="author_admin">{r.author_admin.surname} {r.author_admin.name}</span>
                                                }  
                                            </td>
                                            <td>
                                                {r.message}
                                            </td>
                                            <td>
                                                {r.patient &&
                                                    <div>
                                                        {r.patient.surname} {r.patient.name}
                                                    </div>
                                                }                                                
                                            </td>
                                            <td>
                                                {r.medical_exam &&
                                                    <div>
                                                        {formatDT(r.medical_exam.start_ts)}
                                                    </div>
                                                }                                                
                                            </td>
                                            <td>
                                                {r.user &&
                                                    <div>
                                                        {r.user.surname} {r.user.name}
                                                    </div>
                                                }                                                
                                            </td>                                            
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>  
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onClose() }>Chiudi</Button>
            </Modal.Footer>
        </Modal>
    );
};

export {Logs};