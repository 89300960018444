
import React, { FC, Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Alert, Badge, Button, Card, Col, Row } from "react-bootstrap";
import { RiPlayCircleFill, RiPauseCircleFill, RiStopCircleFill } from "react-icons/ri";
import { CellProps } from "react-table";
import { toast } from "react-toastify";
import { buildGetFetch, buildPostFetch } from "../../../services/base";
import { PaymentService } from "../../../types/paymentservice";
import { formatDT } from "../../../utils/dateutils";
import { Loading } from "../../common/loading";
import { Table } from "../../common/table";
import { UserContext } from "../usercontext";

type ActivePayment = {
    payments?: Array<PaymentService>;
    stats: Array<PaymentStat>;
}

type PaymentStat = {
    id: number;
    period_from: string;
    period_to: string;
    count: number;
}

export const CurrentPayment: FC<{onHistory: () => void, onNew: () => void}> = ({onHistory, onNew}) => {
    
    const { userData } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [currentPayment, setCurrentPayment] = useState<ActivePayment>();

    useEffect(() => {
        loadPayments();
    }, []);

    const loadPayments = () => {
        const [fetchActive, cancel] = buildGetFetch<ActivePayment>(`/user/${userData.user.id}/payment`, {}, setCurrentPayment, setLoading);
        fetchActive();
        return () => {
            cancel();
        }
    };

    const startPayment = (p:any) => {
        setUpdating(true);
        const [post] = buildPostFetch<PaymentService>(`/user/${userData.user.id}/payment/${p.id}/start`, {}, {}, (p) => {
            setUpdating(false);
            loadPayments();
        });
        post();
    };

    const stopPayment = (p:any) => {
        setUpdating(true);
        const [post] = buildPostFetch<PaymentService>(`/user/${userData.user.id}/payment/${p.id}/stop`, {}, {}, (p) => {
            setUpdating(false);
            loadPayments();
        });
        post();
    };

    const pausePayment = (p:any) => {
        console.log("p",p);
        setUpdating(true);
        const [post] = buildPostFetch<PaymentService>(`/user/${userData.user.id}/payment/${p.id}/pause`, {}, {}, (p) => {
            setUpdating(false);
            loadPayments();
        });
        post();
    };

    const columns = useMemo(() => {
        return [{
            Header: 'Periodo',
            Cell: (props: CellProps<any>) => {
                return `${formatDT(props.row.original.period_from)} - ${formatDT(props.row.original.period_to)}`;
            }
        },{
            Header: 'Visite effettuate',
            accessor: 'count'
        }]
    }, []);

    if(loading){
        return <Loading show={true} message="Caricamento servizi..."/>
    }

    const payList:any=currentPayment?.payments?.map(p => {
        return (
            <Card border="info" style={{marginBottom:'6px'}} key={p.id}>
                {p.payment.family === 1 && 
                    <Card.Body style={{display:'flex'}}>
                        <Badge variant="dark">{p.payment.name}</Badge>
                        <div className="mb-1 mr-2 ml-2">
                            <label className="col-form-label">ATTIVAZIONE:</label> 
                            <b>{formatDT(p.start_ts)}</b> 
                        </div>
                        {p.end_ts && 
                            <div className="mb-1 mr-2">
                                <label className="col-form-label">TERMINE:</label> 
                                <b>{formatDT(p.end_ts)}</b> 
                            </div>
                        }     
                        <div className="mb-1 mr-2">
                            <label className="col-form-label">PREZZO:</label> 
                            <b>{p.payment.default_price} &euro;</b> 
                        </div>                       
                        <div className="mb-1 mr-2">
                            <label className="col-form-label">CREDITO:</label> 
                            <b>{p.payment.assigned_budget} &euro;</b> 
                        </div>                       
                    </Card.Body>
                }

                {p.payment.family === 2 && 
                    <Fragment>
                        <Card.Header>
                            <Badge variant="dark">{p.payment.name}</Badge>
                            <div style={{float:'right'}}>
                                <Button style={{marginRight:'4px'}} onClick={() => startPayment(p)} disabled={p.active || !!p.end_ts} variant="success" title="Riattiva il servizio di pagamento">
                                    <RiPlayCircleFill/> Riprendi</Button>
                                <Button style={{marginRight:'4px'}}  onClick={() => pausePayment(p)} disabled={!p.active || !!p.end_ts} variant="warning" title="Sospendi il servizio di pagamento">
                                    <RiPauseCircleFill/> Sospendi</Button>
                                <Button onClick={() => stopPayment(p)} disabled={!!p.end_ts} variant="danger" title="Termina il servizio di pagamento">
                                    <RiStopCircleFill/> Termina</Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Table data={currentPayment.stats} columns={columns}/>
                    </Card.Body>

                    </Fragment>
                }
            </Card>
        )
    })
    
    return (<div>
        <div className="mt-4">
            <Loading show={updating||loading} backdrop={true}/>
            <div className="action-menu">
                <Button onClick={onNew} variant="success">Aggiungi nuovo servizio di pagamento</Button>
                {/*currentPayment && currentPayment.payment && 
                <Fragment>
                    <Button onClick={startPaymeny} disabled={currentPayment.payment.active || !!currentPayment.payment.end_ts} variant="success" title="Riattiva il servizio di pagamento">
                        <RiPlayCircleFill/> Riprendi servizio</Button>
                    <Button onClick={pausePaymeny} disabled={!currentPayment.payment.active || !!currentPayment.payment.end_ts} variant="warning" title="Sospendi il servizio di pagamento">
                        <RiPauseCircleFill/> Sospendi servizio</Button>
                    <Button onClick={stopPaymeny} disabled={!!currentPayment.payment.end_ts} variant="danger" title="Termina il servizio di pagamento">
                        <RiStopCircleFill/> Termina servizio</Button>
                </Fragment>*/}
                <Button onClick={onHistory} variant="info" title="Mostra tutti i servizi di pagamento associati all'utente">Storico servizi</Button>
            </div>
            <div>

                <Card className="mt-3" border="info">
                    <Card.Header>Statistiche utente</Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md={4} sm={4} xs={12}>
                                <div className="indicator shadow-sm text-center p-2">
                                    <label>Credito residuo</label><br />
                                    <h3><Badge variant="primary">{userData.user.assigned_budget-userData.user.used_budget}</Badge></h3>
                                </div>
                            </Col>
                            <Col md={4} sm={4} xs={12}>
                                <div className="indicator shadow-sm text-center p-2">
                                    <label>Credito utilizzato</label><br />
                                    <h3><Badge variant="primary">{userData.user.used_budget}</Badge></h3>
                                </div>

                            </Col>
                            <Col md={4} sm={4} xs={12}>
                                <div className="indicator shadow-sm text-center p-2">
                                    <label>Totale pagamenti</label><br />
                                    <h3><Badge variant="primary">{userData.user.total_pay}</Badge></h3>
                                </div>
                            </Col>
                        </Row>

                        <Row style={{marginTop:'6px'}}>
                            <Col md={4} sm={4} xs={12}>
                                <div className="indicator shadow-sm text-center p-2">
                                    <label>Visite totali</label><br />
                                    <h3><Badge variant="primary">{userData.user.all_exams}</Badge></h3>
                                </div>
                            </Col>
                            <Col md={4} sm={4} xs={12}>
                                <div className="indicator shadow-sm text-center p-2">
                                    <label>Visite ultimo mese</label><br />
                                    <h3><Badge variant="primary">{userData.user.last_month_exams}</Badge></h3>
                                </div>

                            </Col>
                            <Col md={4} sm={4} xs={12}>
                                <div className="indicator shadow-sm text-center p-2">
                                    <label>Visite ultima settimana</label><br />
                                    <h3><Badge variant="primary">{userData.user.last_week_exams}</Badge></h3>
                                </div>
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
                <br/>

                {payList ?
                    <div>{payList}</div>
                    : 
                    <Alert variant="warning">
                        <p>Nessun servizio di pagamento attivo</p>
                    </Alert>
                }


                
            </div>
        </div>
    </div>)
}
