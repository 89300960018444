
import React, { FC, useState, useMemo, useCallback } from "react";
import { buildPostFetch } from "../../services/base";
import { Exam } from "../../types/exam";
import { Spinner, Button } from "react-bootstrap/esm";
import { Table } from "../common/table";
import { CellProps } from "react-table";
import { useHistory } from "react-router-dom";
import { AcUser } from "../common/ac_user";
import { User } from "../../types/user";
import { FastField, Formik } from "formik";
import { Card, Col, Form, InputGroup } from "react-bootstrap";
import { DatePickerField } from "../common/date_picker";
import { formatDT } from "../../utils/dateutils";
import { Loading } from "../common/loading";
import { StateBadge } from "../common/examstatebadge";
import { FaEye, FaSearch } from "react-icons/fa";
import { Id } from "../common/id";

export const ExamsList: FC<{}> = () => {

    const history = useHistory();

    const [exams, setExams] = useState<Array<Exam>>([]);
    const [error, setError] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const columns = useMemo(() => {
        return [{
            accessor: "id",
            Header: "Id",
            Cell: (props: any) => <Id id={props.value}/>
        }, {
            accessor: "state",
            Header: "Stato",
            Cell: (props: any) => {
                return <StateBadge state={props.value} />
            }
        }, {
            Cell: (props: any) => {
                return props.row.original.owner.surname + " " + props.row.original.owner.name;
            },
            Header: "Medico"
        }, {
            Cell: (props: any) => {
                return props.row.original.patient.surname + " " + props.row.original.patient.name;
            },
            Header: "Paziente"
        }, {
            accessor: "title",
            Header: "Visita"
        }, {
            accessor: "start_ts",
            Header: "Data",
            Cell: (props: any) => {
                return formatDT(props.value);
            }
        }, {
            Cell: (props: CellProps<any>) => {
                return (
                    <Button title="Accedi alla visita" size="sm" variant="dark" onClick={() => {
                            history.push(`/app/exams/${props.row.original.id}`);
                        }}><FaEye size={'1.5em'}/></Button>
                    )
            },
            Header: "Azioni"
        }]
    }, []);

    const submit = useCallback((values: any) => {
        const data: any = {};
        /*
        if (values.user) {
            data.user_id = values.user.id;
        }
        */
       data.search=values.search;
        if (values.dpFrom) {
            data.date_start = values.dpFrom;
        }
        if (values.dpTo) {
            var d:Date=new Date(values.dpTo);
            d.setDate(d.getDate()+1);
            data.date_end = d;
        }
        setSearching(true);
        const [fetch, cancel] = buildPostFetch<Array<Exam>>('/exam/search', data, {}, setExams, setSearching, setError);
        fetch();

    }, []);

    if (error) {
        return (<div>Errore</div>);
    }

    if (loading) {
        return (<Spinner animation="border" role="status">
            <span className="sr-only">Caricamento visite...</span>
        </Spinner>);
    }


    return (
        <div>
            <Loading show={searching} backdrop={true}></Loading>
            <Card className="mb-3" border="info">
                <Card.Header>Ricerca visite</Card.Header>
                <Card.Body>
                    <Formik
                        onSubmit={submit}
                        initialValues={{
                            user: null,
                        }}>
                        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>Paziente</Form.Label>
                                        <InputGroup>
                                            <FastField className="form-control" name="search" placeholder="Cerca paziente per nome, cognome o codice fiscale"></FastField>
                                            { /* <AcUser name="user" onSelect={(u: User) => { setFieldValue("user", u, true); }} /> */ }
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Data da:</Form.Label>
                                        <InputGroup>
                                            <DatePickerField maxDate={new Date()} name="dpFrom" placeholder="GG/MM/AAAA" />
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Data a:</Form.Label>
                                        <InputGroup>
                                            <DatePickerField maxDate={new Date()} name="dpTo" placeholder="GG/MM/AAAA" />
                                        </InputGroup>
                                    </Form.Group>
                                    <Col className="align-self-end">
                                        <Button type="submit" style={{position:"relative",top:"-6px"}}><FaSearch/> Cerca</Button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
            </Card>

            { exams &&
                <Card border="info" className="mb-2">
                    <Card.Body>
                        { exams.length>0 && 
                            <Table data={exams} columns={columns} />
                        }
                        { exams.length===0 && 
                            <div>Nessuna registrazione individuata</div>
                        }
                    </Card.Body>
                </Card>
            }                
            
        </div>
    )
}
