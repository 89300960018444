
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Alert, Badge, Button, Card, Col, Collapse, Row } from "react-bootstrap";
import { FaChevronLeft } from "react-icons/fa";
import { CellProps } from "react-table";
import { buildGetFetch } from "../../../services/base";
import { PaymentService } from "../../../types/paymentservice";
import { formatDT } from "../../../utils/dateutils";
import { Loading } from "../../common/loading";
import { Table } from "../../common/table";
import { UserContext } from "../usercontext";

export const PaymentHistory: FC<{ onBack: () => void }> = ({ onBack }) => {

    const { userData } = useContext(UserContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [payments, setPayments] = useState<Array<PaymentService>>([]);

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<Array<PaymentService>>(`/user/${userData.user.id}/payment/history`, {}, setPayments, setLoading);
        fetch();
        return () => {
            cancel();
        }
    }, []);

    if (loading) {
        return <Loading show={true} message="Caricamento storico servizi" />
    }

    return (<div>
        <div className="action-menu">
            <Button onClick={onBack}><FaChevronLeft /> Indietro</Button>
        </div>
        <Card border="info">
            <Card.Header>
                <h5>Storico servizi di pagamento</h5>
            </Card.Header>
            <Card.Body>
                {payments.length === 0 && <Alert variant="warning">
                    <p>Nessun pagamento presente</p>
                </Alert>}
                {payments.reverse().map((p) => {
                    return <PaymentRow key={p.id} payment={p} />
                })}
            </Card.Body>
        </Card>
    </div>)
}

type PaymentRowProps = {
    payment: PaymentService;
}

const PaymentRow: FC<PaymentRowProps> = ({ payment }) => {

    const { userData } = useContext(UserContext);
    const [fade, setFade] = useState<boolean>(false);
    const [stats, setStats] = useState<Array<any>>([]);

    const getStats = useCallback(() => {
        if (!fade) {
            if (payment.payment.family === 2) {
                const [fetch] = buildGetFetch<Array<any>>(`/user/${userData.user.id}/payment/${payment.id}/stats`, {}, (data) => {
                    setStats(data);
                    setFade(true);
                });
                fetch();
            } else {
                setFade(true);
            }
        } else {
            setFade(false);
        }
    }, [payment.id, fade]);

    const columns = useMemo(() => {
        return [{
            Header: 'Periodo',
            Cell: (props: CellProps<any>) => {
                return `${formatDT(props.row.original.period_from)} - ${formatDT(props.row.original.period_to)}`;
            }
        },{
            Header: 'Visite effettuate',
            accessor: 'count'
        }]
    }, [payment.id]);

    return (
        <Alert variant="light" className="services">
            <div className="d-flex justify-content-between">
                <div>
                    <h5>{payment.payment.name}</h5>
                </div>
                <div className="text-right">
                    <p>Attivazione:<b>{formatDT(payment.start_ts)}</b></p>
                    {payment.end_ts && <p>Termine: <b>{formatDT(payment.end_ts)}</b></p>}
                </div>
            </div>
            <div>
                <Button variant="info" size="sm" onClick={getStats}>Mostra utilizzi</Button>
                <Collapse in={fade}>
                    <div className="mt-2">                        
                        {payment.payment.family === 2 &&
                        <div>
                            <Table data={stats} columns={columns}/>
                        </div>}
                    </div>
                </Collapse>
            </div>
        </Alert>)
}