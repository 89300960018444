
import Axios, { Canceler } from 'axios';
import { API_ROUTE, BASE, buildGetFetch } from './base';
import { User } from '../types/user';

interface IAuth {
    /**
     * Start application
     */
    start: () => Promise<any>;
    login: (email: string, password: string) => Promise<any>;
    logout: () => void;
    user: () => any | null;
    sync: (onSync: (user: User|null) => void, onLoad: (load: boolean) => void, onError: (load: boolean) => void) => [() => Promise<User | null>, Canceler];
    isLoggedIn: () => boolean;
    csrf: () => string;
}

export const Auth: IAuth = (() => {
    
    Axios.defaults.withCredentials = true;
    Axios.defaults.baseURL = BASE;

    /**
     * Private logged in variable
     */
    let _isLoggedIn = false;
    /**
     * Private user variable
     */
    let _user: User | null;
    /**
     * could be useful
     */
    let _csrf_token: string = '';
    
    const setupLogin = (user: any) => {
        if(_isLoggedIn === false && !_user){
            _user = user;
            _isLoggedIn = true;
        }
    }
    const setupLogout = () => {
        if(_isLoggedIn === true && _user){
            _user = null;
            _isLoggedIn = false;
        }
    }

    return {
        csrf: () => _csrf_token,
        start: () => {
            return new Promise(async (resolve, reject) => {
                const result = await Axios.get(`/sanctum/csrf-cookie`).catch((reason: any) => {
                    reject(reason);
                    return null;
                });
                if(result){
                    _csrf_token = result.config.headers['X-XSRF-TOKEN'];
                    resolve();
                }
            });
        },
        login: (email: string, password: string) => {
            return new Promise(async (resolve, reject) => {
                const result = await Axios.post<User>(`${API_ROUTE}/login`, {email, password}).catch(() => {
                    reject();
                    return null;
                });
                if(result && result.data){
                    setupLogin(result.data);
                    resolve();
                }
            });
        },
        sync: (onSync: (user: User|null) => void, onLoad: (load: boolean) => void, onError: (load: boolean) => void) => {
            const _sync = (user: User|null) => {
                if(user){
                    console.warn("User is authenticated");
                    setupLogin(user);
                } else {
                    console.warn("User is not authenticated");
                    setupLogout();
                }
                onSync(user);
            }
            return buildGetFetch<any>('/admin', {}, _sync, onLoad, onError);
        },
        logout: () => {
            return new Promise(async (resolve, reject) => {
                await Axios.post(`${API_ROUTE}/logout`, {});
                setupLogout();
                resolve();
            });
        },
        user: (): User | null => {
            return _user;
        },
        isLoggedIn: () => {
            return _isLoggedIn;
        }
    }

})();

