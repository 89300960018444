
import React, { FC, useEffect, useState } from "react";
import { buildGetFetch } from "../../services/base";
import { ResponsiveLine, Serie } from "@nivo/line";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { PaymentService } from "../../types/paymentservice";
import { formatDT } from "../../utils/dateutils";
import { Panel } from "../common/panel";
import { PaymentServiceStatistic } from "../../types/paymentServiceStatistic";
import { Loading } from "../common/loading";
import { FaEye } from "react-icons/fa";
import { useHistory } from "react-router-dom";

type Data = {
    stats: Array<{
        count: number;
        month: number;
        year: number;
    }>;
    warnings: Array<PaymentService>;
    snapshots: Array<PaymentServiceStatistic>;
}

export const Dashboard: FC<{}> = () => {

    const [stats, setStats] = useState<Array<Serie>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [warnings, setWarnings] = useState<Array<PaymentService>>([]);
    const [snapshots, setSnapshots] = useState<Array<PaymentServiceStatistic>>([]);

    const history = useHistory();
    
    useEffect(() => {
        const [fetch] = buildGetFetch<Data>('/admin/snapshot');
        fetch().then((data) => {
            setLoading(false);
            if (data) {
                const values: Array<any> = [];
                data.stats.forEach((s) => {
                    values.push({
                        y: s.count,
                        x: `${s.month}/${s.year}`
                    });
                });
                setStats([{
                    id: "",
                    color: "hsl(96, 70%, 50%)",
                    data: values
                }]);
                setWarnings(data.warnings);
                setSnapshots(data.snapshots);
            }
        });
    }, []);

    return (<div>
        <Loading show={loading} backdrop={true} message="Caricamento Dashboard"/>
        <Panel header="Andamento visite ultimi 12 mesi">
            <div style={{ height: '300px' }}>
                <ResponsiveLine data={stats}
                    margin={{ top: 40, right: 60, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Mese',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Numero visite',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    enablePointLabel={true}
                    lineWidth={4}
                    curve="linear"
                    colors={{ scheme: 'category10' }}
                    pointSize={10}
                    pointColor="#2ac07f"
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true} /></div>
        </Panel>
        <Row>
            <Col lg={12} md={12}>
                <Panel header="Servizi Pacchetto in esaurimento / esauriti">
                    <table className="table table-condensed table-bordered custom-table">
                        <thead style={{ display: 'sticky' }}>
                            <tr>
                                <th>Servizio</th>
                                <th>Utente</th>
                                <th>Consumo</th>
                                <th>Residuo</th>
                                <th>Inizio</th>
                                <th>Termine</th>
                                <th className="minimal-td">Azioni</th>
                            </tr>
                        </thead>
                        <tbody>
                            {warnings.map((w) => {
                                return <tr key={w.id}>
                                    <td>
                                        {w.payment.name} (?)
                                    </td>
                                    <td>
                                        {`${w.user.name} ${w.user.surname}`}
                                    </td>
                                    <td>
                                        ?
                                    </td>
                                    <td>
                                        <Badge variant={0 <= 0 ? "danger" : "warning"}>{Math.max(0-0,0)}</Badge>
                                    </td>
                                    <td>
                                        {formatDT(w.start_ts)}
                                    </td>
                                    <td>
                                        {w.end_ts ? formatDT(w.end_ts) : "In corso"}
                                    </td>
                                    <td className="minimal-td">
                                        <Button title="Accedi alla scheda" size="sm" variant="dark" onClick={() => {
                                            history.push(`/app/users/${w.user.id}`);
                                        }}><FaEye size={'1.5em'}/></Button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </Panel>
            </Col>
            {/*
            <Col lg={5} md={12}>
                <Panel header="Servizi ...">
                    <table className="table table-condensed table-bordered custom-table">
                        <thead style={{ display: 'sticky' }}>
                            <tr>
                                <th>Servizio</th>
                                <th>Utente</th>
                                <th>Visite</th>
                                <th>Periodo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {snapshots.map((s) => {
                                return <tr key={s.id}>
                                    <td>
                                        {s.payment_service.payment.name}
                                    </td>
                                    <td>
                                        {`${s.payment_service.user.name} ${s.payment_service.user.surname}`}
                                    </td>
                                    <td>
                                        {s.count}
                                    </td>
                                    <td>
                                        {`${formatDT(s.period_from)} - ${formatDT(s.period_to)}`}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </Panel>
            </Col>
            */}
        </Row>
    </div>)
}
