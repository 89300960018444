
import React, { FC, useEffect, useState, useMemo } from "react";
import { useHistory, Switch, useRouteMatch, Route } from "react-router-dom";
import { ExamsList } from "./examsList";
import { ExamDetail } from "./examDetail";

export const ExamsCenter: FC<{}> = () => {
    
    let { path, url } = useRouteMatch();

    return (
        <div className="exam-center">

        <Switch>
            <Route exact path={path}>
                <ExamsList></ExamsList>
            </Route>
            <Route path={`${path}/:id`}>
                <ExamDetail></ExamDetail>
            </Route>
        </Switch>
        </div>
    )
}
