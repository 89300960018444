

import React, {FC, useEffect, useState, Fragment} from "react";
import { useParams } from "react-router-dom";
import { Exam } from "../../types/exam";
import { buildGetFetch } from "../../services/base";
import { Spinner, Alert } from "react-bootstrap/esm";

export const ExamDetail: FC<{}> = () => {

    let {id} = useParams<{id:any}>();

    const [exam, setExam] = useState<Exam>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const [fetch, cancel] = buildGetFetch<Exam>(`/exam/${id}`, {}, setExam, setLoading, setError);
        fetch();
    }, []);

    if(error){
        return (<Alert variant="warning">Visita non trovata</Alert>);
    }

    if(loading){
        return (<Spinner animation="border" role="status">
        <span className="sr-only">Caricamento visita...</span>
      </Spinner>);
    }
    return (
        <Fragment>
            <div>Pagina visit</div>
            {exam && 
                <div>
                    Visita {exam.id}
                </div>
            }
        </Fragment>
    )
}