
import React, { FC, useCallback, useContext } from "react";
import { validationSchema } from "../../../utils/validation";
import * as yup from 'yup';
import { Formik, FormikHelpers, useFormikContext } from "formik";
import { Button, Card, Col, Form, FormControl, InputGroup } from "react-bootstrap";
import { DatePickerField } from "../../common/date_picker";
import { MdSave } from "react-icons/md";
import { LoadingButton } from "../../common/loadingbutton";
import { PaymentServiceSelector } from "./paymentServiceSelector";
import { FaChevronLeft } from "react-icons/fa";
import { buildPostFetch } from "../../../services/base";
import { toast } from "react-toastify";
import { UserContext } from "../usercontext";

const schema: yup.ObjectSchema = validationSchema({
    start_ts: yup.date().required(),
    payment_type: yup.number().required()
});
export const NewPayment: FC<{onBack: () => void}> = ({onBack}) => {

    const {userData} = useContext(UserContext);

    const submit = useCallback((values, helpers: FormikHelpers<any>) => {
         const [fetch] = buildPostFetch(`/user/${userData.user.id}/payment`, values, {});
         fetch().then((result) => {
            helpers.setSubmitting(false);
            if(result) {
                toast.success("Nuovo servizio di pagamento aggiunto con successo");
                onBack();
            } else {
                toast.error("Errore nella creazione del servizio. Contatta il supporto");
            }

         })
    }, []);

    return (<div>
        <div className="action-menu">
            <Button variant="warning" onClick={onBack}><FaChevronLeft/> Annulla e torna indietro</Button>
        </div>
        <Card border="info">
            <Card.Header>Aggiunta nuovo servizio</Card.Header>
            <Card.Body>

                <Formik onSubmit={submit} validationSchema={schema} initialValues={{ start_ts: new Date(), payment_type: 100 }}>
                    {({ handleSubmit, isSubmitting, values, handleChange }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="typeField">
                                    <Form.Label>Tipologia servizio</Form.Label>
                                    <PaymentServiceSelector name="payment_type" showPrice={true}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="startField">
                                    <Form.Label>Data di inizio</Form.Label>
                                    <div className="full-width">
                                        <DatePickerField name="start_ts" placeholder="GG/MM/AAAA"/>
                                    </div>
                                </Form.Group>
                            </Form.Row>
                            <LoadingButton disabled={isSubmitting} loading={isSubmitting} type="submit" size="lg" block variant="success">
                                <MdSave size={"1.5rem"}/> Aggiungi servizio
                            </LoadingButton>
                        </Form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
   </div>)
}