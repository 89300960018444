
import React, { FC } from "react";
import { FaVenus, FaMars } from "react-icons/fa"
import { useFormikContext, useField } from "formik"
import Switch from 'react-switch'
import {get} from "lodash";

export const GenderInput: FC<any> = (props) => {

    const {setFieldValue, values} = useFormikContext<any>();
    const [field, meta] = useField(props);
    var value = get(values, props.name);
    return (
        <Switch 
            handleDiameter = {25} 
            offColor = "#f274db" 
            offHandleColor = "#f542d4" 
            onColor = "#86d3ff" 
            onHandleColor = "#2693e6"
            boxShadow = "0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow = "0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height = {26} 
            width = {55}
            uncheckedIcon = {
                <div className = "text-center">
                    <FaVenus size = {'1.5rem'}></FaVenus>
                </div>
            } 
            checkedIcon = {
                <div className = "text-center">
                    <FaMars size = {'1.5rem'}></FaMars>
                </div>
            }
            onChange = {(v: any) => {
                setFieldValue(field.name, v ? 'M' : 'F')
            }} 
            checked = {
                get(values, props.name) === 'M'
            } 
            className = "react-switch"
        />
    )
}