
import { useField, useFormikContext } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import { buildGetFetch } from "../../../services/base";

type PaymentType = {
    code: number;
    id: number;
    name: string;
    family: number;
    // package_count: number;
    default_price: number;
    assigned_budget: number;
}

export const PaymentServiceSelector: FC<{name: string,onChange?:any,showPrice?:boolean}> = (props: any) => {

    const [ paymentTypes, setPaymentTypes ] = useState<Array<PaymentType>>([]);
    const { handleBlur, handleChange } = useFormikContext();
    const [ field ] = useField(props);

    useEffect(() => {
        const [fetch, cancel] = buildGetFetch<Array<PaymentType>>(`/enum/payments`, {}, setPaymentTypes);
        fetch();
        return () => {
            cancel();
        }
    }, []);

    const selectorChanged=useCallback((e) => {
        if(props.onChange) {
            let pmap=paymentTypes.map(p => p.code);
            let idx=pmap.indexOf(parseInt(e.target.value));
            let selected=paymentTypes[idx];
            props.onChange(e,selected);
        }            
        handleChange(e);
    },[paymentTypes]);

    return (<select className="form-control" onChange={selectorChanged} name={field.name} onBlur={handleBlur} >
        {  
            paymentTypes.map((t) => {
                let pdesc="";
                // if(t.package_count) pdesc=" (" + t.package_count + ")";
                if(t.assigned_budget) pdesc=" (budget: " + t.assigned_budget + "€)";
                if(props.showPrice) pdesc=pdesc + " - " + t.default_price + "€"
                return <option key={t.id} value={t.code} label={ t.name + pdesc }></option>
            })
        }
    </select>)

}