import React, { FC, Fragment, useCallback, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { Formik, FormikHelpers, Field } from 'formik';
import * as yup from 'yup';
import { validationSchema } from '../../utils/validation';
import { buildGetFetch, buildPostFetch } from '../../services/base';
import { toast } from 'react-toastify';
import { DatePickerField } from '../common/date_picker';
import { GenderInput } from '../common/genderInput';
import { MdSave } from 'react-icons/md';
import { RoleSelector } from '../common/roleselector';
import { cloneDeep } from 'lodash';
import { LoadingButton } from '../common/loadingbutton';
import Switch from 'react-switch';
import { AppSwitch } from '../common/app_switch';

type NewUserProps = {
    onCreate: (newUser: number) => void;
}

const schema: yup.ObjectSchema = validationSchema({
    name: yup.string().required(),
    surname: yup.string().required(),
    email: yup.string().email().test(
        'is-unique-email',
        'Email non valida o già in uso',
        async function (value: any) {
            if (!value || value === '') {
                return false;
            }
            const [fetch] = buildGetFetch<boolean>(
                '/user/validate',
                {
                    queryParams: {
                        email: value
                    }
                }
            )
            return !await fetch();
        }
    ),
    address: yup.object().shape({
        taxcode: yup.string().required().length(16).test(
            'is-unique-cf',
            'Codice fiscale già in uso',
            async function (value: any) {
                if (!value || value === '') {
                    return false;
                }
                const [fetch] = buildGetFetch<boolean>(
                    '/user/validate',
                    {
                        queryParams: {
                            taxcode: value
                        }
                    }
                )
                return !await fetch();
            }
        ),
        vatnumber: yup.string().min(11).max(13).test(
            'is-unique-cf',
            'Partita iva già in uso',
            async function (value: any) {
                if (!value || value === '') {
                    return true;
                }
                const [fetch] = buildGetFetch<boolean>(
                    '/user/validate',
                    {
                        queryParams: {
                            vatnumber: value
                        }
                    }
                )
                return !await fetch();
            }
        ),
        date_of_birth: yup.date().required(),
        genre: yup.string(),
        address_line1: yup.string(),
        address_line2: yup.string(),
        zipcode: yup.string().test(
            "zipcode-is-valid",
            "Cap non valido. ",
            function(value) {
                if (value == undefined) {
                    return true;
                }
                var digits = /^[0-9]+$/;
                var isValid = true;
                if (value?.length != 5) {
                    isValid = false;
                }
                if (!value.match(digits)) {
                    isValid = false;
                }

                return isValid;
            }
        ),
        cell1: yup.number(),
        cell2: yup.number(),
        city: yup.string(),
        country: yup.string(),
        province: yup.string(),
        pec: yup.string().email().test(
            'is-unique-pec',
            'Email non valida o già in uso',
            async function (value: any) {
                if (!value || value === '') {
                    return true;
                }
                const [fetch] = buildGetFetch<boolean>(
                    '/user/validate',
                    {
                        queryParams: {
                            pec: value
                        }
                    }
                )
                return !await fetch();
            }
        ),
        sdi: yup.string().test(
            "sdi-is-valid",
            "Codice SDI non valido. ",
            function(value) {
                if (value == undefined) {
                    return true;
                }
                var sdiTest = /^[A-Z0-9]{6,7}$/;
                var isValid = true;
                if (!value.match(sdiTest)) {
                    isValid = false;
                }
                return isValid;
            }
        )
    })
});


const NewUser: FC<NewUserProps> = ({onCreate}) => {

    const submit = useCallback((values, helpers: FormikHelpers<any>) => {
        if(!values.internal) {
            let ok=true;
            if(!values.address.vatnumber){
                toast.error("Partita IVA necessaria per utente non interno");
                ok=false;
            }
            if(!values.address.sdi && !values.address.pec){
                toast.error("Codice SDI o indirizzo pec necessario per utente non interno");
                ok=false;
            }
            if(!ok){
                helpers.setSubmitting(false);
                return;
            }
        }
        const data = cloneDeep(values);
        let birth_date = data.address.date_of_birth;
        let utc = new Date(
            birth_date.getTime() - birth_date.getTimezoneOffset() * 60000
        );
        data.address.date_of_birth = utc;
        const [create] = buildPostFetch<any>("/user", data, {toastError: true, toastMessage: 'Errore inserimento utente'});
        create().then((data) => {
            helpers.setSubmitting(false);
            if(data !== null){
                toast.success("Utente creato con successo");
                helpers.resetForm();
                onCreate(data.user.id);
            }
        })
    }, []);

    return (<div>
        <Formik
            validateOnBlur = {false}
            validateOnChange = {false}
            validationSchema = {schema}
            onSubmit = {submit}
            initialValues = {{
                name: '',
                surname: '',
                role: 2,
                email: "",
                internal: false,
                address: {
                    vatnumber: "",
                    taxcode: '',
                    date_of_birth: '',
                    genre: "F",
                    address_line1: "",
                    address_line2: "",
                    zipcode: "",
                    cell1: "",
                    cell2: "",
                    city: "",
                    country: "",
                    province: "",
                    pec: "",
                    sdi: "",
                    specialization: "",
                    presentation: ""
                }
            }
        }>
            {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Row>
                        <Form.Group as={Col} controlId="nameField">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" placeholder="Inserisci nome" name="name" value={values.name}
                                onChange={handleChange} isValid={touched.name && !errors.name} isInvalid={touched.name && !!errors.name} />
                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="surnameField">
                            <Form.Label>Cognome</Form.Label>
                            <Form.Control type="text" placeholder="Inserisci cognome" name="surname" value={values.surname}
                                onChange={handleChange} isValid={touched.surname && !errors.surname} isInvalid={touched.surname && !!errors.surname} />
                            <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Col md={6}>
                            <RoleSelector editing={true} onRoleChange={handleChange} currentRole={values.role} name="role"></RoleSelector>
                        </Col>

                        <Form.Group as={Col} controlId="internalField">
                            <Fragment>
                                <Form.Label>Interno</Form.Label>
                                <br/>
                                <AppSwitch name="internal" />
                            </Fragment>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="birthField">
                            <Form.Label>Data di nascita</Form.Label>
                            <br/>
                            <DatePickerField maxDate={new Date()} name="address.date_of_birth" placeholder="GG/MM/AAAA"/>
                            <Form.Control.Feedback type="invalid">{errors.address?.date_of_birth}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} controlId="sexField">
                            <Form.Label>Genere</Form.Label>
                            <br/>
                            <GenderInput name="address.genre" value={values.address.genre || "F"}/>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Col sm={6}>
                            <Form.Label htmlFor="emailField">Email</Form.Label>
                            <Form.Control type="email" placeholder="Inserisci email" name="email" value={values.email}
                                onChange={handleChange} isValid={touched.email && !errors.email} isInvalid={touched.email && !!errors.email} />
                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                        </Col>
                    </Form.Row>

                    <div className="d-flex mb-4 mt-4">
                        <fieldset>
                            <legend>Informazioni</legend>
                            <Form.Row>
                                <Form.Group as={Col} controlId="specialization">
                                    <Form.Label>Specializzazioni</Form.Label>
                                    <Form.Control placeholder="Inserisci specializzazioni" name="address.specialization" value={values.address.specialization}
                                        onChange={handleChange} />
                                    <div className="specialization-tool">(inserisci campi separati da virgole)</div>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="presentation">
                                    <Form.Label>Presentazione</Form.Label>
                                    <Form.Control as="textarea" placeholder="Inserisci presentazione" name="address.presentation" value={values.address.presentation}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Form.Row>
                        </fieldset>
                    </div>

                    <div className="d-flex mb-4 mt-4">
                        <fieldset>
                            <legend>Indirizzo</legend>
                            <Form.Row>
                                <Form.Group as={Col} controlId="address1">
                                    <Form.Label>Indirizzo 1</Form.Label>
                                    <Form.Control placeholder="Inserisci indirizzo riga 1" name="address.address_line1" value={values.address.address_line1}
                                        onChange={handleChange} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="address2">
                                    <Form.Label>Indirizzo 2</Form.Label>
                                    <Form.Control placeholder="Inserisci indirizzo riga 2" name="address.address_line2" value={values.address.address_line2}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="city">
                                    <Form.Label>Citta</Form.Label>
                                    <Form.Control placeholder="Inserisci città" name="address.city" value={values.address.city}
                                        onChange={handleChange} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="state">
                                    <Form.Label>Stato</Form.Label>
                                    <Form.Control placeholder="Inserisci stato" name="address.country" value={values.address.country}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="zipcode">
                                    <Form.Label>Cap</Form.Label>
                                    <Form.Control
                                        placeholder="Inserisci cap"
                                        name="address.zipcode"
                                        value={values.address.zipcode}
                                        onChange={handleChange}
                                        isValid={touched.address?.zipcode && !errors.address?.zipcode}
                                        isInvalid={touched.address?.zipcode && !!errors.address?.zipcode} />
                                    <Form.Control.Feedback type="invalid">{errors.address?.zipcode}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="province">
                                    <Form.Label>Provincia</Form.Label>
                                    <Form.Control as="select" name="address.province"
                                        onChange={handleChange}>
                                        <option value="0">-- SELEZIONA PROVINCIA --</option>
                                        <option value="ag">Agrigento</option>
                                        <option value="al">Alessandria</option>
                                        <option value="an">Ancona</option>
                                        <option value="ao">Aosta</option>
                                        <option value="ar">Arezzo</option>
                                        <option value="ap">Ascoli Piceno</option>
                                        <option value="at">Asti</option>
                                        <option value="av">Avellino</option>
                                        <option value="ba">Bari</option>
                                        <option value="bt">Barletta-Andria-Trani</option>
                                        <option value="bl">Belluno</option>
                                        <option value="bn">Benevento</option>
                                        <option value="bg">Bergamo</option>
                                        <option value="bi">Biella</option>
                                        <option value="bo">Bologna</option>
                                        <option value="bz">Bolzano</option>
                                        <option value="bs">Brescia</option>
                                        <option value="br">Brindisi</option>
                                        <option value="ca">Cagliari</option>
                                        <option value="cl">Caltanissetta</option>
                                        <option value="cb">Campobasso</option>
                                        <option value="ci">Carbonia-iglesias</option>
                                        <option value="ce">Caserta</option>
                                        <option value="ct">Catania</option>
                                        <option value="cz">Catanzaro</option>
                                        <option value="ch">Chieti</option>
                                        <option value="co">Como</option>
                                        <option value="cs">Cosenza</option>
                                        <option value="cr">Cremona</option>
                                        <option value="kr">Crotone</option>
                                        <option value="cn">Cuneo</option>
                                        <option value="en">Enna</option>
                                        <option value="fm">Fermo</option>
                                        <option value="fe">Ferrara</option>
                                        <option value="fi">Firenze</option>
                                        <option value="fg">Foggia</option>
                                        <option value="fc">Forl&igrave;-Cesena</option>
                                        <option value="fr">Frosinone</option>
                                        <option value="ge">Genova</option>
                                        <option value="go">Gorizia</option>
                                        <option value="gr">Grosseto</option>
                                        <option value="im">Imperia</option>
                                        <option value="is">Isernia</option>
                                        <option value="sp">La spezia</option>
                                        <option value="aq">L'aquila</option>
                                        <option value="lt">Latina</option>
                                        <option value="le">Lecce</option>
                                        <option value="lc">Lecco</option>
                                        <option value="li">Livorno</option>
                                        <option value="lo">Lodi</option>
                                        <option value="lu">Lucca</option>
                                        <option value="mc">Macerata</option>
                                        <option value="mn">Mantova</option>
                                        <option value="ms">Massa-Carrara</option>
                                        <option value="mt">Matera</option>
                                        <option value="vs">Medio Campidano</option>
                                        <option value="me">Messina</option>
                                        <option value="mi">Milano</option>
                                        <option value="mo">Modena</option>
                                        <option value="mb">Monza e della Brianza</option>
                                        <option value="na">Napoli</option>
                                        <option value="no">Novara</option>
                                        <option value="nu">Nuoro</option>
                                        <option value="og">Ogliastra</option>
                                        <option value="ot">Olbia-Tempio</option>
                                        <option value="or">Oristano</option>
                                        <option value="pd">Padova</option>
                                        <option value="pa">Palermo</option>
                                        <option value="pr">Parma</option>
                                        <option value="pv">Pavia</option>
                                        <option value="pg">Perugia</option>
                                        <option value="pu">Pesaro e Urbino</option>
                                        <option value="pe">Pescara</option>
                                        <option value="pc">Piacenza</option>
                                        <option value="pi">Pisa</option>
                                        <option value="pt">Pistoia</option>
                                        <option value="pn">Pordenone</option>
                                        <option value="pz">Potenza</option>
                                        <option value="po">Prato</option>
                                        <option value="rg">Ragusa</option>
                                        <option value="ra">Ravenna</option>
                                        <option value="rc">Reggio di Calabria</option>
                                        <option value="re">Reggio nell'Emilia</option>
                                        <option value="ri">Rieti</option>
                                        <option value="rn">Rimini</option>
                                        <option value="rm">Roma</option>
                                        <option value="ro">Rovigo</option>
                                        <option value="sa">Salerno</option>
                                        <option value="ss">Sassari</option>
                                        <option value="sv">Savona</option>
                                        <option value="si">Siena</option>
                                        <option value="sr">Siracusa</option>
                                        <option value="so">Sondrio</option>
                                        <option value="ta">Taranto</option>
                                        <option value="te">Teramo</option>
                                        <option value="tr">Terni</option>
                                        <option value="to">Torino</option>
                                        <option value="tp">Trapani</option>
                                        <option value="tn">Trento</option>
                                        <option value="tv">Treviso</option>
                                        <option value="ts">Trieste</option>
                                        <option value="ud">Udine</option>
                                        <option value="va">Varese</option>
                                        <option value="ve">Venezia</option>
                                        <option value="vb">Verbano-Cusio-Ossola</option>
                                        <option value="vc">Vercelli</option>
                                        <option value="vr">Verona</option>
                                        <option value="vv">Vibo valentia</option>
                                        <option value="vi">Vicenza</option>
                                        <option value="vt">Viterbo</option>
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>
                        </fieldset>
                        <fieldset>
                            <legend>Contatti</legend>
                            <Form.Row>
                                <Form.Group as={Col} controlId="taxcodeField">
                                    <Form.Label>Codice fiscale</Form.Label>
                                    <Form.Control type="text" placeholder="Inserisci codice fiscale" name="address.taxcode" value={values.address.taxcode}
                                        onChange={handleChange} isValid={touched.address?.taxcode && !errors.address?.taxcode} isInvalid={touched.address?.taxcode && !!errors.address?.taxcode} />
                                    <Form.Control.Feedback type="invalid">{errors.address?.taxcode}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="vatnumberField">
                                    <Form.Label>P.IVA di fatturazione</Form.Label>
                                    <Form.Control type="text" placeholder="Inserisci partita IVA" name="address.vatnumber" value={values.address.vatnumber}
                                        onChange={handleChange} isValid={touched.address?.vatnumber && !errors.address?.vatnumber} isInvalid={touched.address?.vatnumber && !!errors.address?.vatnumber} />
                                    <Form.Control.Feedback type="invalid">{errors.address?.vatnumber}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="pec">
                                    <Form.Label>Pec</Form.Label>
                                    <Form.Control placeholder="Inserisci la pec" name="address.pec" value={values.address.pec}
                                        onChange={handleChange} isValid={touched.address?.pec && !errors.address?.pec} isInvalid={touched.address?.pec && !!errors.address?.pec} />
                                        <Form.Control.Feedback type="invalid">{errors.address?.pec} </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="sdi">
                                    <Form.Label>Sdi</Form.Label>
                                    <Form.Control placeholder="Inserisci lo sdi" name="address.sdi" value={values.address.sdi} 
                                        onChange={handleChange} isValid={touched.address?.sdi && !errors.address?.sdi} isInvalid={touched.address?.sdi && !!errors.address?.sdi} />
                                    <Form.Control.Feedback type="invalid">{errors.address?.sdi}</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} controlId="cell1">
                                    <Form.Label>Telefono fisso</Form.Label>
                                    <Form.Control placeholder="Inserisci numero telefono fisso" name="address.cell1" value={values.address.cell1}
                                        onChange={handleChange} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="cell2">
                                    <Form.Label>Cellulare</Form.Label>
                                    <Form.Control placeholder="Inserisci numero di cellulare" name="address.cell2" value={values.address.cell2}
                                        onChange={handleChange} />
                                </Form.Group>
                            </Form.Row>
                        </fieldset>
                    </div>
                    <LoadingButton disabled={isSubmitting} loading={isSubmitting} type="submit" size="lg" block variant="success"><MdSave size={"1.5rem"}/> Aggiungi utente</LoadingButton>
                </Form>
            )}
        </Formik>
    </div>)
}

export { NewUser };