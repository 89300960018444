
import React, { FC } from "react";
import { useFormikContext, useField } from "formik"
import Switch from 'react-switch'
import {get} from "lodash";

export const AppSwitch: FC<any> = (props) => {

    const {setFieldValue, values} = useFormikContext<any>();
    const [field, meta] = useField(props);
    var value = get(values, props.name);
    return (
        <Switch
            handleDiameter = {25}
            boxShadow = "0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow = "0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height = {26}
            width = {55}
            onChange = {(v: any) => {
                setFieldValue(field.name, v)
            }}
            checked = {
                get(values, props.name)
            }
            className = "react-switch"
        />
    )
}