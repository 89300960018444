
import React, { FC, useEffect, useState, useMemo } from "react";
import { buildGetFetch } from "../../services/base";
import { Patient } from "../../types/patient";
import { Spinner, Button } from "react-bootstrap/esm";
import { Table } from "../common/table";
import {CellProps, Cell} from "react-table";
import { useHistory } from "react-router-dom";

export const PatientsList: FC<{}> = () => {

    const history = useHistory();

    const [patients, setPatients] = useState<Array<Patient>>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const [fetch, cancel] = buildGetFetch<Array<Patient>>('/patient', {}, setPatients, setLoading, setError);
        fetch();
    }, []);

    const columns = useMemo(() => {
        return [{
            accessor: "id",
            Header: "Identificativo"
        },{
            accessor: "name",
            Header: "Nome"
        },{
            accessor: "surname",
            Header: "Cognome"
        },{
            Cell: (props: CellProps<any>) => {
                return (<Button onClick={() => {
                    history.push(`/app/patients/${props.row.original.id}`);
                }} title="Vai al paziente">Vai</Button>)
            },
            Header: "Azioni"
        }]
    }, []);

    if(error){
        return (<div>Errore</div>);
    }

    if(loading){
        return (<Spinner animation="border" role="status">
        <span className="sr-only">Caricamento pazienti...</span>
      </Spinner>);
    }

    return (
        <div>
            <h3>Lista pazienti</h3>
            <Table data={patients} columns={columns}/>
        </div>
    )
}
