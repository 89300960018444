
import React, { FC, useEffect } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { Backdrop } from "./backdrop";

type LoadingProps = {
    message?: string;
    show: boolean;
    backdrop?: boolean;
}

export const Loading: FC<LoadingProps> = ({show, message, backdrop = false}) => {


    if(!show){
        return <div/>
    }
    if(backdrop){
        return <Backdrop>
            <Spinner style={{margin:'auto'}} animation="border" variant="info" /> 
            <span color="white">{message || 'Caricamento...'}</span>
        </Backdrop>
    }
    return <Alert variant="info">
        <div className="d-flex align-items-center">
            <Spinner animation="border" variant="info" /> 
            <div>
                <h5 style={{margin: '0rem 0rem 0rem 1rem'}}>{message || 'Caricamento...'}</h5>
            </div>
        </div></Alert>
}
