import React, { FC, useEffect, useState, Fragment } from "react";
import { useFormikContext, useField } from "formik";
import { Form, Col } from "react-bootstrap";
import { get } from "lodash";
import { buildGetFetch } from "../../services/base";

type RoleSelectorProps = {
    editing:boolean;
    currentRole: number;
    onRoleChange: any;
    name: string;
}

export const RoleSelector: FC<RoleSelectorProps> = ({currentRole, editing, onRoleChange, name}) => {
    const {values, handleChange, setFieldValue}= useFormikContext();
    const [field,meta] = useField(name);
    const [roles, setRoles] = useState<Array<any>>([]);

    useEffect(()=>{
        const [fetch, cancel] = buildGetFetch<Array<any>>('/enum/roles', {}, setRoles);
        fetch();
        return () => cancel();
    }, []);
    if(editing)
    {
        return(
            <Fragment>
                <Form.Label htmlFor="roleSelector">Ruolo</Form.Label>
                <Form.Control id="roleSelector" as="select" onChange={(e: any) => {
                    console.log("!",e.target.value);
                    setFieldValue(name, e.target.value);
                }} value={get(values, name)}>
                    {roles.map((r)=> {
                        return <option key={r.id} value={r.id}>{r.name}</option>
                    })}
                </Form.Control>
            </Fragment>
        )
    }
    else{
        return (
            <span>{roles.find((r)=>r.id===currentRole)?.name}</span>
        )
    }
}