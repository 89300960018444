
import React, {FC} from 'react';
import { Badge } from 'react-bootstrap';

export const Genre: FC<{genre: "M" | "F"}> = ({genre}) => {
    //console.log("Genre : " + genre);
    if (genre === 'M') {
        return <Badge pill className="badge-male">Maschio</Badge>;
    }
    else if (genre === 'F') {
        return <Badge pill className="badge-female">Femmina</Badge>;
    }
    else {
        return <Badge pill className="badge-none">Nessuno</Badge>;
    }
    return <Badge/>;
}