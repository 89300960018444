
/**
 * Validation utils based on YUP
 * https://www.npmjs.com/package/yup
 */

import { setLocale, object } from 'yup';

setLocale({
    mixed: {
      default: 'Invalido',
      required: 'Campo obbligatorio',
      notOneOf: 'Valore non rinosciuto',
      notType: 'Tipo non riconosciuto',
      oneOf: 'Valore non rinosciuto'
    },
    number: {
      min: 'Deve essere minore di ${min}',
      max: 'Deve essere maggiore di ${max}',
    },
    string: {
        email: 'Email non valida',
        length: 'Il campo deve essere di ${length} caratteri',
        min: 'Il campo deve essere di almeno ${min} caratteri',
        max: 'Il campo non deve superare i ${max} caratteri',
    }
});

export const validationSchema = (fields?: any) => {
    return object(fields);
}