

const VERSION = '1.0.1-dev';
const VERSION_KEY = `${process.env.REACT_APP_NAME}_FE_VERSION`;
const DATE_KEY = `${process.env.REACT_APP_NAME}_FE_UPDATE`;
const ENV = `${process.env.REACT_APP_NAME}_FE_ENV`;


export const setupVersioning = () => {
    if (localStorage) {
        const oldVal = localStorage.getItem(VERSION_KEY);
        if(oldVal == null || oldVal !== VERSION){
            localStorage.setItem(DATE_KEY, new Date().toJSON());
        }
        localStorage.setItem(VERSION_KEY, VERSION);
        localStorage.setItem(ENV,  process.env.NODE_ENV === 'production' ? 'PROD' : 'DEV');
    } 
}