
import './app.scss';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Nav, Navbar, Collapse } from 'react-bootstrap';
import { useRouteMatch, Switch, Route, NavLink } from 'react-router-dom';
import { Nomatch } from './common/nomatch';
import { FaClinicMedical, FaCogs, FaFileMedicalAlt, FaUserCircle, FaUsers } from 'react-icons/fa';
import { Auth } from '../services/auth';
import { OverflowWrapper } from './common/overflowwrapper';
import clsx from 'clsx';
import { UsersCenter } from './users/usersCenter';
import { PatientsCenter } from './patients/patientsCenter';
import { ExamsCenter } from './exams/examsCenter';
import { SettingsCenter } from './settings/settingsCenter';
import { Dashboard } from './dash/dashboard';
import { GiExitDoor } from 'react-icons/gi';
import { toast } from 'react-toastify';
import { MdNewReleases } from 'react-icons/md';

const App: FC = (props: any) => {

  let { path, url } = useRouteMatch();

  const [collapsed, setCollapsed] = useState(false);
  const [version, setVersion] = useState<string>();
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);
  
  const handleCollapse = () => {
    setCollapsed((prev) => !prev);
  };

  useEffect(() => {
    let savedAppInfoAsString=localStorage.getItem("app-info");
    let savedAppInfo=null;
    if(savedAppInfoAsString) {
        try {
            savedAppInfo=JSON.parse(savedAppInfoAsString);
        } catch(e) {
            savedAppInfo=null;
        }
    }
    if(savedAppInfo && savedAppInfo.version) {
      console.log("savedAppInfo",savedAppInfo)
      setVersion(savedAppInfo.version);
      checkNewVersionAvailable(savedAppInfo.version);
    } else {
      setVersion("[no version]");
    }
  },[]);

  const checkNewVersionAvailable = (vv:string) => {
    if(!vv) {
      console.log("No saved version, considering no update available");
      return;
    }
    var myHeaders = new Headers();
            myHeaders.append('pragma', 'no-cache');
            myHeaders.append('cache-control', 'no-cache');
    var myInit = {
            method: 'GET',
            headers: myHeaders,
        };
    fetch('/app-info.json',myInit)
      .then((response) => response.json())
      .then((serverInfo) => {
          console.log("Saved version present, comparing versions",vv,serverInfo.version);
          if(vv!=serverInfo.version) {
              console.log("Version mismatch, an update is available....");
              setNewVersionAvailable(true);
              toast.warning(`E' disponibile un aggiornamento software. Utilizza lo strumento vicino al numero di versione per aggiornare`);
          }
    });
  }

  const updateApp = useCallback(() => {
      window.location.href="/";
  },[]);

  return (
    <div className="App">
      <span className="app-version">
          Versione: {version}
          { newVersionAvailable &&
            <span title="Aggiornamento disponibile. Premi per aggiornare" onClick={updateApp}>
              <MdNewReleases />
            </span>
          }
      </span>
      <header >
      </header>
      <Navbar bg="dark" variant="dark" className="navbar" expand="lg">
        <Navbar.Brand href="/app">
          DermaCo
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <FaUserCircle size={'1rem'}/> {Auth.user()?.name + " " + Auth.user()?.surname}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      <Container fluid>
        <Row className="flex-xl-nowrap">
          <Col xl={2} md={3} className="sidebar">
            <button title={collapsed ? 'Chiudi menu' : 'Apri menu'} onClick={handleCollapse} className={clsx(collapsed ? 'hide-menu' : 'show-menu' )}>
              <div className="content"></div>
            </button>
            <Collapse in={collapsed}>
              <OverflowWrapper>
                <Nav className={`flex-column`}>
                    <NavLink exact to={`${url}`} className="nav-link" activeClassName="active">
                     <FaClinicMedical size={'2.5rem'} className="mr-4 ml-4" />Home
                    </NavLink>
                    <NavLink exact to={`${url}/users`}  isActive={(match, location) => {
                      if (match) {
                        return true;
                      }
                      return location.pathname.startsWith(`${url}/users`);
                    }} className="nav-link" activeClassName="active">
                      <FaUsers size={'2.5rem'} className="mr-4 ml-4" />Utenti
                    </NavLink>
                    { /*
                    <NavLink exact to={`${url}/patients`} className="nav-link" activeClassName="active">
                      <FaBriefcaseMedical size={'2.5rem'} className="mr-4 ml-4" />Pazienti
                    </NavLink>
                    */ }
                    {/*
                    <NavLink exact to={`${url}/exams`} isActive={(match, location) => {
                      if (match) {
                        return true;
                      }
                      return location.pathname.startsWith(`${url}/exams`);
                    }} className="nav-link" activeClassName="active">
                      <FaFileMedicalAlt size={'2.5rem'} className="mr-4 ml-4" />Visite
                    </NavLink>
                    */}
                    <NavLink exact to={`${url}/settings`} className="nav-link" activeClassName="active">
                      <FaCogs size={'2.5rem'} className="mr-4 ml-4" />Impostazioni
                    </NavLink>
                    <NavLink exact to={``} onClick={() => { Auth.logout() }} className="nav-link" activeClassName="active">
                      <GiExitDoor size={'2.5rem'} className="mr-4 ml-4" />Esci
                    </NavLink>
                </Nav>
              </OverflowWrapper>
            </Collapse>
          </Col>
          <main className="col-xl-10 col-md-9 col-12">
            <Switch>
              <Route exact path={path}>
                <Dashboard/>
              </Route>
              <Route path={`${path}/users`}>
                <UsersCenter/>
              </Route>
              <Route path={`${path}/patients`}>
                <PatientsCenter/>
              </Route>
              <Route path={`${path}/exams`}>
                <ExamsCenter/>
              </Route>
              <Route exact path={`${path}/settings`}>
                <SettingsCenter/>
              </Route>
              <Route path="*">
                <Nomatch />
              </Route>
            </Switch>
          </main>
        </Row>
      </Container>
      <footer>

      </footer>
    </div>
  );
}


export { App };
