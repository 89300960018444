
import React, { FC } from "react";

export const Panel: FC<{header: string}> = ({children, header}) => {
    return (<div className="panel">
        <div className="header">
            <h5>{header}</h5>
        </div>
        <div className="content">
            {children}
        </div>
    </div>)
}