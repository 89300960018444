
import React, {FC} from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';

type TableProps = {
    columns: Array<any>;
    data: Array<any>;
}

export const Table: FC<TableProps> = ({columns, data}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page
    
        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({ columns, data, initialState: { pageIndex: 0 } }, useSortBy, usePagination);
    return (
    <>
    <table {...getTableProps()} className="table table-sm table-striped table-bordered table-hover">
        <thead>
        {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
            </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row,i) => {
            prepareRow(row)
            return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
            )
        })}
        </tbody>
    </table>
      <div className="pagination form-inline">
        <span className="p-3">
          <button className="btn btn-info" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {'<<'}
          </button>
        </span>
        <span className="p-3">
          <button className="btn btn-info" onClick={() => previousPage()} disabled={!canPreviousPage}>
            {'<'}
          </button>{' '}
        </span>
        <span className="p-3">
          <button className="btn btn-info" onClick={() => nextPage()} disabled={!canNextPage}>
            {'>'}
          </button>{' '}
        </span>
        <span className="p-3">
          <button className="btn btn-info" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </button>{' '}
        </span>
        <span className="p-3">
          Pagina{' '}
          <strong>
            {pageIndex + 1} di {pageOptions.length}
          </strong>{' '}
        </span>
        <span className="p-3">
          | Vai a pagina:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            max={pageOptions.length}
            min='0'
            className="form-control"
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
            className="form-control"
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Mostra {pageSize} righe
            </option>
          ))}
        </select>
      </div>
    </>
    )
}