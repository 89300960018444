
import  { createContext } from 'react';
import { UserData } from '../../types/user';

type UserContextType = {
    userData: UserData;
    changeTab: (key: string) => void;
}

// @ts-ignore
const UserContext = createContext<UserContextType>();

export { UserContext }