
import React, {FC, useEffect, useState, Fragment, AllHTMLAttributes} from "react";
import { Button, Spinner, ButtonProps } from "react-bootstrap";

type LoadingButtonProps = {
    loading: boolean;
} 

const LoadingButton: FC<LoadingButtonProps & ButtonProps & any> = ({loading, children, ...props}) => {
    const [_loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(loading);
    }, [loading]);
    return (
    <Button disabled={_loading} {...props} >
        {_loading ? <Fragment>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
            <span className="sr-only">Invio...</span>
        </Fragment> : children}
    </Button>
)
}

export {LoadingButton};