
import React, { FC, useEffect, useState, useMemo } from "react";
import { useHistory, Switch, useRouteMatch, Route } from "react-router-dom";
import { PatientsList } from "./patientsList";
import { PatientDetail } from "./patientDetail";

export const PatientsCenter: FC<{}> = () => {
    
    let { path, url } = useRouteMatch();

    return (
        <div className="patient-center">

        <Switch>
            <Route exact path={path}>
                <PatientsList></PatientsList>
            </Route>
            <Route path={`${path}/:id`}>
                <PatientDetail></PatientDetail>
            </Route>
        </Switch>
        </div>
    )
}
