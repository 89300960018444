
import React, { FC, useState } from "react";
import { CurrentPayment } from "./currentPayment";
import { NewPayment } from "./newpayment";
import { PaymentHistory } from "./paymentHistory";

export const PaymentCenter : FC<{}> = () => {

    const [view, setView] = useState<string>("current");
    
    return (<div>
            <div className="mt-4">
                    {view === "current" && <CurrentPayment onNew={() => {setView("new")}} onHistory={() => {setView("history");}}/>}
                    {view === "history" && <PaymentHistory onBack={() => {setView("current");}}/>}
                    {view === "new" && <NewPayment onBack={() => {setView("current");}}/>}
            </div>
        </div>)
}
