
import React, { FC, useEffect, useState, useMemo } from "react";
import { useHistory, Switch, useRouteMatch, Route } from "react-router-dom";
import { UsersList } from "./usersList";
import { UserDetail } from "./userDetail";

export const UsersCenter: FC<{}> = () => {
    
    let { path, url } = useRouteMatch();

    return (
        <div className="user-center">

        <Switch>
            <Route exact path={path}>
                <UsersList></UsersList>
            </Route>
            <Route path={`${path}/:id`}>
                <UserDetail></UserDetail>
            </Route>
        </Switch>
        </div>
    )
}
