
import React, { FC, useState, useMemo, useCallback, useEffect } from "react";
import { buildPostFetch } from "../../services/base";
import { User } from "../../types/user";
import { Button } from "react-bootstrap/esm";
import { Alert, InputGroup, Modal, Col, Form, Card, Badge } from 'react-bootstrap';
import { Table } from "../common/table";
import { CellProps } from "react-table";
import { useHistory } from "react-router-dom";
import { NewUser } from "./userNew";
import { FastField, Formik } from "formik";
import { FaEye, FaPlus, FaSearch } from "react-icons/fa";
import { Loading } from "../common/loading";
import { Id } from "../common/id";

export const UsersList: FC<{}> = () => {
    const [show, setShow] = useState(false);

    const history = useHistory();

    const [users, setUsers] = useState<Array<User>>([]);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onUserCreate = useCallback((id) => {
        history.push(`/app/users/${id}`)
    },[]);

    const columns = useMemo(() => {
        return [{
            accessor: "id",
            Header: "Id",
            Cell: (props: any) => <Id id={props.value}/>
        },{
            Header: "Ruolo",
            Cell: (props: CellProps<any>) => {
                let badges:any=[];
                if(props.row.original.roles) {
                    badges=props.row.original.roles.map((r:any) => <Badge key={r.id} variant="warning">{r.role.name}</Badge>);
                }
                return <div>{badges}</div>;
            }               
        },{
            Header: "Nominativo",
            Cell: (props:any) => <span>{props.row.original.surname} {props.row.original.name}</span>
        },{
            accessor: "email",
            Header: "Email"
        },{
            accessor: "user_contact_info.vatnumber",
            Header: "P.Iva"
        },{
            accessor: "user_contact_info.taxcode",
            Header: "Cod. fiscale"
        },{
            Cell: (props: CellProps<any>) => {
                return (
                    <Button title="Accedi alla scheda" size="sm" variant="dark" onClick={() => {
                        history.push(`/app/users/${props.row.original.id}`);
                    }}><FaEye size={'1.5em'}/></Button>
                )
            },
            Header: "Azioni"
        }]
    }, []);    

    const doSearch = (values:any) => {
        console.log("search",values);
        const data = {
            value: values.search,
            utype: values.utype
        }
        setLoading(true);
        const [fetch, cancel] = buildPostFetch<Array<User>>('/user/search', data, {}, setUsers, setLoading, setError);
        fetch();
    }

    const submit = useCallback((values: any) => {
        doSearch(values);
    }, []);

    useEffect(() => {
        doSearch({
            search: ""
        });
    },[]);

    if(error){
        return (<Alert variant="danger">Contatta il supporto</Alert>);
    }

    return (
        <div>
            <Loading show={loading} backdrop={true}/>

            <Card className="mb-3" border="info">
                <Card.Header>
                    <div className="d-flex justify-content-between align-items-center">
                        Ricerca utenti
                        <div style={{float:"right"}}>
                            <Button onClick={() => {setShow(true)}}><FaPlus/> Nuovo utente</Button>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Formik
                        onSubmit={submit}
                        initialValues={{
                            search: '',
                        }}>
                        {({ setFieldValue,handleSubmit }) => (
                            <div className="d-flex justify-content-between">
                                <Form noValidate onSubmit={handleSubmit} style={{flexGrow: 1}}>
                                    <Form.Row>
                                        <Form.Group as={Col} style={{flexGrow:'0.5'}} controlId="formGridType">
                                            <select className="form-control" onChange={(e) => setFieldValue('utype',e.target.value) }>
                                                <option value={0} label="Tutti"/>
                                                <option value={2} label="Tecnico"/>
                                                <option value={3} label="Specialista"/>
                                                <option value={4} label="Medico di base"/>
                                                <option value={1} label="Amministratore"/>
                                            </select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <InputGroup>
                                                <FastField className="form-control" name="search" placeholder="Cerca utente per nome, cognome, codice fiscale o partita IVA"></FastField>
                                                <InputGroup.Append>
                                                    <Button type="submit"><FaSearch/> Cerca</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>

                                        <Col>
                                        </Col>
                                    </Form.Row>
                                </Form>
                                
                            </div>
                        )}
                    </Formik>
                </Card.Body>
            </Card>

            { users &&
                <Card border="info" className="mb-2">
                    <Card.Body>
                        { users.length>0 && 
                            <div className="user-table-container">
                                <Table data={users} columns={columns} />
                            </div>
                        }
                        { users.length===0 && 
                            <div>Nessuna registrazione individuata</div>
                        }
                    </Card.Body>
                </Card>
            }    

            <Modal show={show} size='xl' onHide={() => setShow(false)} aria-labelledby="new user dialog">
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Nuovo utente
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewUser onCreate={onUserCreate}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}
