
import { parse } from "date-fns/esm";
import { Formik } from "formik";
import { update } from "lodash";
import React, { FC, useEffect, useState, useCallback } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import { buildGetFetch, buildPostFetch, buildPutFetch } from "../../services/base";
import { Setting } from "../../types/setting";
import { Loading } from "../common/loading";

type Calendar = {
    id: number;
    day_of_week: 1 | 2 | 3 | 4 | 5 | 6;
    day_time: 1 | 2;
    phone_number: string;
}

export const Support: FC<{}> = () => {

    const [error, setError] = useState<boolean>(false);
    const [timetable, setTimeTable] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [calendar, setCalendar] = useState<Array<Calendar>>();

    useEffect(() => {
        const [fetchCalendar, cancelCalendar] = buildGetFetch<Array<Calendar>>("/admin/calendar", {});
        const [fetchSettings, cancelSettings] = buildPostFetch<Array<Setting>>(`/admin/settings`, { keys: ["MORNING_FROM", "MORNING_TO", "AFTERNOON_FROM", "AFTERNOON_TO"] }, {});
        Promise.all([fetchCalendar(), fetchSettings()]).then(([calendar, data]) => {
            setLoading(false);
            if (data) {
                let timeStr = data.find((d) => {
                    return d.setting_key === 'MORNING_FROM';
                })?.setting_value;
                const morningFromTime = timeStr ? parse(timeStr, "HH:mm", new Date()) : new Date();

                timeStr = data.find((d) => {
                    return d.setting_key === 'MORNING_TO';
                })?.setting_value;
                const morningToTime = timeStr ? parse(timeStr, "HH:mm", new Date()) : new Date();

                timeStr = data.find((d) => {
                    return d.setting_key === 'AFTERNOON_FROM';
                })?.setting_value;
                const afternoonFromTime = timeStr ? parse(timeStr, "HH:mm", new Date()) : new Date();

                timeStr = data.find((d) => {
                    return d.setting_key === 'AFTERNOON_TO';
                })?.setting_value;
                const afternoonToTime = timeStr ? parse(timeStr, "HH:mm", new Date()) : new Date();

                const formData: any = {
                    MORNING_FROM: morningFromTime,
                    MORNING_TO: morningToTime,
                    AFTERNOON_FROM: afternoonFromTime,
                    AFTERNOON_TO: afternoonToTime
                }
                setTimeTable(formData);
            }
            if (calendar) {
                setCalendar(calendar);
            }
        });
        return () => {
            cancelCalendar();
            cancelSettings();
        }
    }, []);

    const submit = useCallback((values: any) => {
        setLoading(true);
        const data: Array<any> = [];
        Object.keys(values).map((k) => {
            let min = values[k].getMinutes();
            min = ("0" + min).slice(-2);

            let hh = values[k].getHours();
            hh = ("0" + hh).slice(-2);

            const dt = hh + ":" + min;
            data.push({
                setting_key: k,
                setting_value: dt
            })
        })
        const [fetch] = buildPutFetch<Array<any>>('/admin/settings', { settings: data }, {}, setTimeTable, setLoading, setError);
        fetch().then(() => {
            toast.success("Orari aggiornati");
        });

    }, []);

    const submitCalendar = (values: {calendar: Array<Calendar>}) => {
        setLoading(true);
        const [updateCalendar] = buildPutFetch<Array<Calendar>>("/admin/calendar", {calendar: values.calendar}, {}, setCalendar, setLoading);
        updateCalendar().then(() => {
            toast.success("Calendario aggiornato");
        });
    };

    if (timetable && calendar) {
        return (
            <Card border="info" className="mt-2 mb-2">
                <Card.Body>
                    <div className="mt-4">
                        <Loading show={loading} backdrop={true}/>
                        <Row>
                            <Col lg={8} md={12}>
                                <h5>Calendario supporto Whatsapp </h5>
                                <Alert variant="info">
                                    <p>Inserisci i numeri di telefono senza spazi e con l'eventuale codice internazionale es. <b>+393401234567</b></p>
                                </Alert>
                                <Formik
                                    onSubmit={submitCalendar}
                                    initialValues={
                                        {calendar}
                                    }>
                                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => (
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <table className="table table-condensed table-bordered custom-table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '80px' }} />
                                                        <th>Lunedì</th>
                                                        <th>Martedì</th>
                                                        <th>Mercoledì</th>
                                                        <th>Giovedì</th>
                                                        <th>Venerdì</th>
                                                        <th>Sabato</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="header">Mattina</td>
                                                        <td className="phono">
                                                            <input className="form-control" value={values.calendar[0].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[0].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[1].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[1].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[2].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[2].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[3].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[3].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[4].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[4].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[5].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[5].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="header">Pomeriggio</td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[6].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[6].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[7].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[7].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[8].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[8].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[9].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[9].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[10].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[10].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                        <td className="phono">
                                                            <input className="form-control" name="phono_number" value={values.calendar[11].phone_number} onChange={(e) => {
                                                                setFieldValue("calendar[11].phone_number", e.target.value);
                                                            }}/>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div>
                                                <Button block type="submit"><FaSave/> Aggiorna calendario</Button>
                                            </div>
                                    </Form>
                                )}
                                </Formik>
                            </Col>
                            <Col lg={4} md={12}>
                                <div>
                                    <Formik
                                        onSubmit={submit}
                                        initialValues={
                                            timetable
                                        }>
                                        {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                        <h5>Orario mattina</h5>
                                                        <div className="pb-3">
                                                            <Form.Label className="settings-label">Dalle:</Form.Label>&nbsp;
                                                <ReactDatePicker
                                                                selected={values.MORNING_FROM}
                                                                onChange={(date: any) => { setFieldValue("MORNING_FROM", date) }}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={30}
                                                                locale="it"
                                                                timeCaption="Time"
                                                                className="form-control"
                                                                dateFormat="HH:mm"></ReactDatePicker>
                                                        </div>

                                                        <div>
                                                            <Form.Label className="settings-label">Alle:</Form.Label>&nbsp;
                                                <ReactDatePicker
                                                                selected={values.MORNING_TO}
                                                                onChange={(date: any) => { setFieldValue("MORNING_TO", date) }}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={30}
                                                                locale="it"
                                                                timeCaption="Time"
                                                                className="form-control"
                                                                dateFormat="HH:mm"></ReactDatePicker>
                                                        </div>
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                        <h5>Orario pomeriggio</h5>
                                                        <div className="pb-3">
                                                            <Form.Label className="settings-label">Dalle:</Form.Label>&nbsp;
                                                <ReactDatePicker
                                                                selected={values.AFTERNOON_FROM}
                                                                onChange={(date: any) => { setFieldValue("AFTERNOON_FROM", date) }}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={30}
                                                                locale="it"
                                                                timeCaption="Time"
                                                                className="form-control"
                                                                dateFormat="HH:mm"></ReactDatePicker>
                                                        </div>

                                                        <div>
                                                            <Form.Label className="settings-label">Alle:</Form.Label>&nbsp;
                                                <ReactDatePicker
                                                                selected={values.AFTERNOON_TO}
                                                                onChange={(date: any) => { setFieldValue("AFTERNOON_TO", date) }}
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={30}
                                                                locale="it"
                                                                timeCaption="Time"
                                                                className="form-control"
                                                                dateFormat="HH:mm"></ReactDatePicker>
                                                        </div>
                                                    </Form.Group>
                                                </Form.Row>
                                                <div>
                                                    <Button block type="submit"><FaSave/> Aggiorna impostazioni</Button>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>                
            </Card>
        )
    }
    else {
        return (<div className="mt-3">
            <Loading show={true} message="Caricamento impostazioni..."/>
        </div>);
    }
}
