
import React, { FC, useState, useCallback, useEffect, Fragment, useRef } from 'react';
import { UserData } from '../../types/user';
import { FormikHelpers, Formik, FastField, Field } from 'formik';
import { Card, Button, Badge, ButtonGroup, Form, Alert, FormGroup, Col, Row, Modal } from 'react-bootstrap';
import { MdSave, MdEdit, MdPets } from 'react-icons/md';
import { Loading } from '../common/loading';
import { StickyBar } from '../common/stickybar';
import { Confirm } from '../common/confirm';
import { cloneDeep } from 'lodash';
import { fakeId } from '../../utils/faker';
import { formatDT, formatTS } from '../../utils/dateutils';
import clsx from 'clsx';
import { buildGetFetch, buildPostFetch, buildPutFetch } from '../../services/base';
import { GenderInput } from '../common/genderInput';
import { DatePickerField } from '../common/date_picker';
import { Genre } from '../common/genre';
import { Role } from '../../types/role';
import { toast } from 'react-toastify';
import { Logs } from '../common/logs';
import { AppSwitch } from '../common/app_switch';

let reset: any;
type UserCardProps = {
    userData: UserData;
    onUpdate?: (userData: UserData) => void;
}

export const UserCard: FC<UserCardProps> = ({ userData, onUpdate }) => {

    const [editing, setEditing] = useState<boolean>(false);
    const [updating, setUpdating] = useState<boolean>(false);
    const [cancelConfirm, setCancelConfirm] = useState<boolean>(false);
    const [roles, setRoles] = useState<Array<Role>>([]);
    const [askChangeEmail,setAskChangeEmail] = useState<boolean>(false);
    const [showingLogs, setShowingLogs] = useState<boolean>(false);

    let emailInput = useRef<HTMLInputElement>(null);

    const onSubmit = useCallback((values: UserData, helpers: FormikHelpers<UserData>) => {
        setUpdating(true);

        if(!values.user.internal) {
            let ok=true;
            if(!values.address.vatnumber){
                toast.error("Partita IVA necessaria per utente non interno");
                ok=false;
            }
            if(!values.address.sdi && !values.address.pec){
                toast.error("Codice SDI o indirizzo pec necessario per utente non interno");
                ok=false;
            }
            if(!ok){
                setUpdating(false);
                return;
            }
        }

        const [fetch] = buildPutFetch<any>("/user", values, {toastError: true, toastMessage: 'Errore modifica utente'});
        fetch().then((data) => {
            helpers.setSubmitting(false);
            if (data !== null) {
                setEditing(false);
                if (onUpdate) {
                    onUpdate(data);
                }
                toast.success("Utente aggiornato con successo");
            }
            setUpdating(false);
        });
    }, [userData]);

    const cancelForm = useCallback(() => {
        reset();
        setEditing(false);
        setCancelConfirm(false);
    }, []);

    useEffect(() => {
        const [fetch] = buildGetFetch('/enum/roles', {}, setRoles);
        fetch();
    }, []);

    const changeEmail=useCallback(()=> {
        const email = emailInput.current?.value;
        if(!email) {
            toast.error("Email non valida");
            return;
        }

        toast.info("Operazione in corso...");
        const [check] = buildGetFetch<boolean>(
            '/user/validate',
            {
                queryParams: {
                    email: email,
                    skipUser: userData.user.id
                }
            }
        )
        check().then((present) => {
            if(present) {
                toast.error("Email non valida o già in uso");
            } else {
                var values:any={
                    user: userData.user.id,
                    email: email
                }
                const [fetch] = buildPutFetch<any>("/user/changeemail", values, {toastError: true, toastMessage: 'Errore modifica email'});
                fetch().then((data) => {
                    toast.success("Utente aggiornato con successo, ricaricamento scheda...");
                    window.location.reload();
                });
            }
        });

    },[]);

    return (
        <Fragment>
            <Modal show={askChangeEmail} dialogClassName="modal-lg">
                <Modal.Header><h5>Cambiamento email</h5></Modal.Header>
                <Modal.Body>
                    <Alert variant="danger">
                        L'indirizzo email coincide con il nome utente.
                        Cambiando questa informazione le credenziali varierano e il sistema invierà nuovamente le informazioni di accesso all'utente.
                    </Alert>
                    <div className="form-group">
                        <label htmlFor="newEmailInput">Nuova email</label>
                        <Form.Control ref={emailInput} defaultValue={userData.user.email} type="email" placeholder="Nome utente" />
                    </div>
                    <div className="action-menu">
                        <Button onClick={changeEmail} className="btn-success">
                            Esegui
                        </Button>
                        <Button onClick={() => {setAskChangeEmail(false)}}  className="btn-warning">
                            Annulla
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Card border="info" className="mb-2">
                <Card.Header className={clsx(!editing && "sticky")}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h5>
                                <Badge variant="dark">{fakeId(userData?.user?.id)}</Badge>&nbsp;
                                { userData.user.surname } { userData.user.name } &mdash; { userData.user.email } &nbsp;
                                <a className="btn btn-xs btn-warning" onClick={() => setAskChangeEmail(true) }>
                                    <MdEdit />
                                </a>
                            </h5>
                        </div>
                        <Button disabled={editing} variant="info" title="Attiva modifica" onClick={() => { setEditing(true) }}><MdEdit /></Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Loading show={updating} backdrop={true} message="Salvataggio in corso..." />
                    {cancelConfirm && <Confirm headerMessage="Hai effettuato delle modifiche al utente"
                        message="Sei sicuro di voler annullare le modifiche senza salvare?"
                        onConfirm={cancelForm}
                        ok="Si"
                        cancel="No"
                        onCancel={() => { setCancelConfirm(false) }} />}
                    <Formik onSubmit={onSubmit} initialValues={cloneDeep(userData)} >
                        {({ resetForm, handleSubmit, setFieldValue, handleChange, dirty, values }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <StickyBar top={49}>
                                    {editing && <Alert variant="warning">
                                        <div className="text-center">
                                            <p>Modifica attiva</p>
                                            <ButtonGroup>
                                                <Button type="button" onClick={() => {
                                                    reset = resetForm;
                                                    if (dirty) {
                                                        setCancelConfirm(true);
                                                    } else {
                                                        cancelForm();
                                                    }
                                                }} variant="warning"><MdSave /> Annulla modifiche</Button>
                                                <Button disabled={!dirty} variant="success" type="submit"><MdSave /> Aggiorna dati utente</Button>
                                            </ButtonGroup>
                                        </div>
                                    </Alert>}
                                </StickyBar>

                                <FormGroup as={Row}>
                                    <Form.Label column sm="3">COGNOME: </Form.Label>
                                    <Col sm="9" className="form-value align-self-center">
                                        {editing ? <FastField as={Form.Control} name="user.surname" onChange={handleChange} value={values.user.surname} /> : <div>{userData.user.surname}</div>}
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm="3">NOME: </Form.Label>
                                    <Col sm="9" className="form-value align-self-center">
                                        {editing ? <FastField as={Form.Control} name="user.name" onChange={handleChange} value={values.user.name} /> : <div>{userData.user.name}</div>}
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm="3">GENERE: </Form.Label>
                                    <Col sm="9" className="form-value align-self-center">
                                        {editing ?
                                            <GenderInput name="address.genre"/> :
                                            <p>
                                                <Genre genre={userData.address?.genre}></Genre>
                                            </p>
                                        }
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row}>
                                    <Form.Label column sm="3">DATA DI NASCITA: </Form.Label>
                                    <Col sm="9" className="form-value align-self-center">
                                        {editing ?
                                            <DatePickerField
                                                name="address.date_of_birth"
                                                placeholder="GG/MM/AAAA" /> :
                                            <div>
                                                {userData.address?.date_of_birth ?
                                                    formatDT(userData.address?.date_of_birth) :
                                                    '1980-01-01'}
                                            </div>
                                        }
                                    </Col>
                                </FormGroup>
                                <FormGroup as={Row}>
                                    <Form.Label column sm="3">RUOLO: </Form.Label>
                                    <Col sm="9" className="form-value align-self-center">
                                        {editing ?
                                            <Field className="form-control" value={values.user.roles[0].role_id} as="select" name="user.roles[0]" onChange={(ev: any) => {
                                                setFieldValue("user.roles", [{ role_id: ev.target.value }], true);
                                            }}>
                                                {roles.map(({ id, name }, index) => <option key={id} value={id} >{name}</option>)}
                                            </Field> :
                                            <div>
                                                {userData.user.roles[0].role.name}
                                            </div>
                                        }
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row}>
                                    <Form.Label column sm="3">INTERNO</Form.Label>
                                    <br/>
                                    <Col sm="9" className="form-value align-self-center">
                                        {editing ?
                                            <AppSwitch name="user.internal" />:
                                            <div>
                                                {userData.user.internal == true?
                                                    'SI' :
                                                    'NO'
                                                }
                                            </div>
                                        }
                                    </Col>
                                </FormGroup>

                                <div className="d-flex mb-4 mt-4">
                                    <fieldset>
                                        <legend>Informazioni</legend>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">SPECIALIZZAZIONI: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? 
                                                    <Fragment>
                                                        <FastField as={Form.Control} name="address.specialization" onChange={handleChange} value={values.address?.specialization} /> 
                                                        <div className="specialization-tool">(inserisci campi separati da virgole)</div>
                                                    </Fragment>
                                                    : 
                                                    <div>
                                                        { values.address?.specialization &&
                                                            values.address.specialization.split(',').map((e:any,i:number) => 
                                                                <Badge variant="secondary" className="specialization-badge">{e}</Badge>
                                                            )
                                                        }
                                                    </div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">PRESENTAZIONE: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="address.presentation" onChange={handleChange} value={values.address?.presentation} /> : <div>{values.address?.presentation}</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">DESCRIZIONE TARIFFE: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="user.fares" onChange={handleChange} value={values.user?.fares} /> : <div>{values.user?.fares}</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">COSTO CONSULENZA: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="user.advice_cost" type="number" onChange={handleChange} value={values.user?.advice_cost} /> : <div>{values.user?.advice_cost}</div>}
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                </div>

                                <div className="d-flex mb-4 mt-4">
                                    <fieldset>
                                        <legend>Indirizzo</legend>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">INDIRIZZO 1: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="address.address_line1" onChange={handleChange} value={values.address?.address_line1} /> : <div>{userData.address?.address_line1}</div>}
                                            </Col>
                                        </FormGroup>

                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">INDIRIZZO 2: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="address.address_line2" onChange={handleChange} value={values.address?.address_line2 || ""} /> : <div>{userData.address?.address_line2}</div>}
                                            </Col>
                                        </FormGroup>

                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">CITT&Agrave;: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="address.city" onChange={handleChange} value={values.address?.city} /> : <div>{userData.address?.city}</div>}
                                            </Col>
                                        </FormGroup>

                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">CAP: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="address.zipcode" onChange={handleChange} value={values.address?.zipcode} /> : <div>{userData.address?.zipcode}</div>}
                                            </Col>
                                        </FormGroup>

                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">PROVINCIA: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ?
                                                    <FastField
                                                        as={Form.Control}
                                                        name="address.province"
                                                        onChange={handleChange}
                                                        value={values.address?.province} /> :
                                                        // <Form.Control as="select" name="address.province"
                                                        //     onChange={handleChange}>
                                                        //     <option value="0">-- SELEZIONA PROVINCIA --</option>
                                                        //     <option value="ag">Agrigento</option>
                                                        //     <option value="al">Alessandria</option>
                                                        //     <option value="an">Ancona</option>
                                                        //     <option value="ao">Aosta</option>
                                                        //     <option value="ar">Arezzo</option>
                                                        //     <option value="ap">Ascoli Piceno</option>
                                                        //     <option value="at">Asti</option>
                                                        //     <option value="av">Avellino</option>
                                                        //     <option value="ba">Bari</option>
                                                        //     <option value="bt">Barletta-Andria-Trani</option>
                                                        //     <option value="bl">Belluno</option>
                                                        //     <option value="bn">Benevento</option>
                                                        //     <option value="bg">Bergamo</option>
                                                        //     <option value="bi">Biella</option>
                                                        //     <option value="bo">Bologna</option>
                                                        //     <option value="bz">Bolzano</option>
                                                        //     <option value="bs">Brescia</option>
                                                        //     <option value="br">Brindisi</option>
                                                        //     <option value="ca">Cagliari</option>
                                                        //     <option value="cl">Caltanissetta</option>
                                                        //     <option value="cb">Campobasso</option>
                                                        //     <option value="ci">Carbonia-iglesias</option>
                                                        //     <option value="ce">Caserta</option>
                                                        //     <option value="ct">Catania</option>
                                                        //     <option value="cz">Catanzaro</option>
                                                        //     <option value="ch">Chieti</option>
                                                        //     <option value="co">Como</option>
                                                        //     <option value="cs">Cosenza</option>
                                                        //     <option value="cr">Cremona</option>
                                                        //     <option value="kr">Crotone</option>
                                                        //     <option value="cn">Cuneo</option>
                                                        //     <option value="en">Enna</option>
                                                        //     <option value="fm">Fermo</option>
                                                        //     <option value="fe">Ferrara</option>
                                                        //     <option value="fi">Firenze</option>
                                                        //     <option value="fg">Foggia</option>
                                                        //     <option value="fc">Forl&igrave;-Cesena</option>
                                                        //     <option value="fr">Frosinone</option>
                                                        //     <option value="ge">Genova</option>
                                                        //     <option value="go">Gorizia</option>
                                                        //     <option value="gr">Grosseto</option>
                                                        //     <option value="im">Imperia</option>
                                                        //     <option value="is">Isernia</option>
                                                        //     <option value="sp">La spezia</option>
                                                        //     <option value="aq">L'aquila</option>
                                                        //     <option value="lt">Latina</option>
                                                        //     <option value="le">Lecce</option>
                                                        //     <option value="lc">Lecco</option>
                                                        //     <option value="li">Livorno</option>
                                                        //     <option value="lo">Lodi</option>
                                                        //     <option value="lu">Lucca</option>
                                                        //     <option value="mc">Macerata</option>
                                                        //     <option value="mn">Mantova</option>
                                                        //     <option value="ms">Massa-Carrara</option>
                                                        //     <option value="mt">Matera</option>
                                                        //     <option value="vs">Medio Campidano</option>
                                                        //     <option value="me">Messina</option>
                                                        //     <option value="mi">Milano</option>
                                                        //     <option value="mo">Modena</option>
                                                        //     <option value="mb">Monza e della Brianza</option>
                                                        //     <option value="na">Napoli</option>
                                                        //     <option value="no">Novara</option>
                                                        //     <option value="nu">Nuoro</option>
                                                        //     <option value="og">Ogliastra</option>
                                                        //     <option value="ot">Olbia-Tempio</option>
                                                        //     <option value="or">Oristano</option>
                                                        //     <option value="pd">Padova</option>
                                                        //     <option value="pa">Palermo</option>
                                                        //     <option value="pr">Parma</option>
                                                        //     <option value="pv">Pavia</option>
                                                        //     <option value="pg">Perugia</option>
                                                        //     <option value="pu">Pesaro e Urbino</option>
                                                        //     <option value="pe">Pescara</option>
                                                        //     <option value="pc">Piacenza</option>
                                                        //     <option value="pi">Pisa</option>
                                                        //     <option value="pt">Pistoia</option>
                                                        //     <option value="pn">Pordenone</option>
                                                        //     <option value="pz">Potenza</option>
                                                        //     <option value="po">Prato</option>
                                                        //     <option value="rg">Ragusa</option>
                                                        //     <option value="ra">Ravenna</option>
                                                        //     <option value="rc">Reggio di Calabria</option>
                                                        //     <option value="re">Reggio nell'Emilia</option>
                                                        //     <option value="ri">Rieti</option>
                                                        //     <option value="rn">Rimini</option>
                                                        //     <option value="rm">Roma</option>
                                                        //     <option value="ro">Rovigo</option>
                                                        //     <option value="sa">Salerno</option>
                                                        //     <option value="ss">Sassari</option>
                                                        //     <option value="sv">Savona</option>
                                                        //     <option value="si">Siena</option>
                                                        //     <option value="sr">Siracusa</option>
                                                        //     <option value="so">Sondrio</option>
                                                        //     <option value="ta">Taranto</option>
                                                        //     <option value="te">Teramo</option>
                                                        //     <option value="tr">Terni</option>
                                                        //     <option value="to">Torino</option>
                                                        //     <option value="tp">Trapani</option>
                                                        //     <option value="tn">Trento</option>
                                                        //     <option value="tv">Treviso</option>
                                                        //     <option value="ts">Trieste</option>
                                                        //     <option value="ud">Udine</option>
                                                        //     <option value="va">Varese</option>
                                                        //     <option value="ve">Venezia</option>
                                                        //     <option value="vb">Verbano-Cusio-Ossola</option>
                                                        //     <option value="vc">Vercelli</option>
                                                        //     <option value="vr">Verona</option>
                                                        //     <option value="vv">Vibo valentia</option>
                                                        //     <option value="vi">Vicenza</option>
                                                        //     <option value="vt">Viterbo</option>
                                                        // </Form.Control>:
                                                        <div>
                                                            {userData.address?.province}
                                                        </div>}
                                            </Col>
                                        </FormGroup>

                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">NAZIONE: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} name="address.country" onChange={handleChange} value={values.address?.country} /> : <div>{userData.address?.country}</div>}
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                    <fieldset>
                                        <legend>Contatti</legend>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">CODICE FISCALE: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} maxLength={16} name="address.taxcode" onChange={handleChange} value={values.address?.taxcode} /> : <div>{userData.address?.taxcode}</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">P.IVA DI FATTURAZIONE: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} maxLength={16} name="address.vatnumber" onChange={handleChange} value={values.address?.vatnumber} /> : <div>{userData.address?.vatnumber}</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">PEC: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} maxLength={16} name="address.pec" onChange={handleChange} value={values.address?.pec || ""} /> : <div>{userData.address?.pec}</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">CODICE UNIVOCO (SDI): </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} maxLength={16} name="address.sdi" onChange={handleChange} value={values.address?.sdi || ""} /> : <div>{userData.address?.sdi}</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">TELEFONO: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} maxLength={16} name="address.cell2" onChange={handleChange} value={values.address?.cell2 || ""} /> : <div>{userData.address?.cell2}</div>}
                                            </Col>
                                        </FormGroup>
                                        <FormGroup as={Row}>
                                            <Form.Label column sm="3">CELLULARE: </Form.Label>
                                            <Col sm="9" className="form-value align-self-center">
                                                {editing ? <FastField as={Form.Control} maxLength={16} name="address.cell1" onChange={handleChange} value={values.address?.cell1 || ""} /> : <div>{userData.address?.cell1}</div>}
                                            </Col>
                                        </FormGroup>
                                    </fieldset>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            Data inserimento: {formatDT(userData.user.created_at)}
                        </div>
                        {userData.user.updated_at &&
                            <span>
                                Ultimo aggiornamento: {formatTS(userData.user.updated_at)}
                                <a className="btn btn-xs btn-logs" onClick={()=> setShowingLogs(true)}>
                                    <MdPets />
                                </a>
                            </span>
                        }
                    </div>
                </Card.Footer>
            </Card>
            { showingLogs &&
                <Logs user={userData.user} onClose={()=>setShowingLogs(false)} />
            }
        </Fragment>
    )
};