

import React, { FC, useEffect, useState, useCallback, Fragment } from "react";
import { useParams } from "react-router-dom";
import {  UserData } from "../../types/user";
import { buildGetFetch } from "../../services/base";
import {  Alert } from "react-bootstrap/esm";
import { UserCard } from "./userCard";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { fakeId } from "../../utils/faker";
import { UserContext } from "./usercontext";
import { PaymentCenter } from "./payment/paymentcenter";
import { Loading } from "../common/loading";

interface UserDetailProps {
}

const UserDetail: FC<UserDetailProps> = () => {

    let { id } = useParams<{ id: any }>();

    const [tabKey, setTabKey] = useState('an');
    const [userData, setUserData] = useState<UserData>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const [fetch, cancel] = buildGetFetch<UserData>(`/user/${id}`, {}, setUserData, setLoading, setError);
        fetch();
        return () => {
            cancel();
        }
    }, []);

    const reloadUser = useCallback((newUserData: UserData) => {
        setUserData(newUserData);
    }, []);

    if (error) {
        return (<Alert variant="warning">Utente non trovato</Alert>);
    }

    if (loading) {
        return (<Loading show={true} message="Caricamento utente..."/>);
    }
    return (
        <div>
            {userData &&
                <UserContext.Provider value={{
                    userData,
                    changeTab: setTabKey
                }}> <Fragment>
                        <h3 className="mb-3">
                            <Badge variant="dark">{fakeId(userData.user.id)}</Badge>&nbsp;
                            { userData &&
                                <Fragment>{userData.user.surname} {userData.user.name} &mdash; { userData.user.email }</Fragment>
                            }
                        </h3>
                        <Tabs activeKey={tabKey} id="tabs" onSelect={(k: string) => { setTabKey(k) }}>
                            <Tab eventKey="an" title="Anagrafica">
                                <div className="mt-3">
                                    {tabKey === "an" && <UserCard userData={userData} onUpdate={reloadUser}></UserCard>}
                                </div>
                            </Tab>
                            <Tab eventKey="pay" title="Servizi di pagamento">
                                <div className="mt-3">
                                    {tabKey === "pay" && <PaymentCenter />}
                                </div>
                            </Tab>
                        </Tabs>
                    </Fragment>
                </UserContext.Provider>
            }
        </div>
    )
};
export { UserDetail }